import { Component,OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { } from '@agm/core/services/google-maps-types';
import { HeaderService } from '../../SharedResources/Services/header.service';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { environment } from "../../../environments/environment";
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './our-branches.html',
})

export class ourBranchesComponent implements OnInit { 
    public storeList:any=[];
    public latitude = 24.774265;
    public longitude = 46.738586;
    public store_name:string;
    public mobile_number:string;
    public address:string;
    public previous:any;
    public zoom: number = 8;
    public openedWindow : any;
    public LANG:any;
    public storesLoaded:boolean=false;
    public showMap:boolean=true;
    public query:any;
    public icon: any="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets/images/icons/map_logo.png";
    public subscriptions:Subscription[]=[]
    public show_clear:boolean=false;



    

    constructor(private mapsAPILoader: MapsAPILoader,private header:HeaderService,private shared:sharedService,private seo:SeoService,private home:HomeService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("our-branches")){
                this.changeLanguage();
                this.showMap=false;
                this.getStoreList();
                this.latitude=24.774265
                this.longitude=46.738586
            }
          }))
          this.changeLanguage();

        window.scroll(0,0)
    }

    ngOnInit(){
        this.getStoreList()
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
      }

    clickedMarker(m:any,infowindow:any) {
        this.store_name=m.title
        this.mobile_number=m.telephone
        this.address=m.address
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
    }

      viewMap(data:any){
          window.scrollTo({
            top: 100,
            behavior: 'smooth',
          })
          this.latitude=parseFloat(data.latitude)
          this.longitude=parseFloat(data.longitude)
          this.zoom=18
          this.openedWindow = data.id;
          this.store_name=data.title
          this.mobile_number=data.telephone
          this.address=data.address
          this.show_clear=true
      }

      isInfoWindowOpen(id) {
        return this.openedWindow == id; 
    }

    

    getStoreList(){
      this.subscriptions.push(this.header.storeList().subscribe(result=>{
            if(result){
                this.storeList=result['response']
                this.storesLoaded=true
                if(this.storeList.length == 0){
                    this.showMap=false
                }else{
                    this.showMap=true
                }
                this.storeList.map(data=>{
                  if(data.open){
                      data.close=this.convertTime(data.close)
                  }
                  if(data.opens){
                    data.opens=this.convertTime(data.opens)
                  }
                  if(data.close){
                      data.open=this.convertTime(data.open)
                  }
                  if(data.closes){
                    data.closes=this.convertTime(data.closes)
                  }
              })
            }
        }))
    }

    clear(){
        this.query=""
        this.showMap=false
        this.show_clear=false
        this.zoom = 8;
        if(this.previous){
            this.previous.close()
        }
        this.previous=""
        setTimeout(() => {
            this.showMap=true
        }, 50);
    }

    getSeoDetails(){
      this.subscriptions.push(this.home.getSeoDetals("10").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }

      convertTime (time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { 
          time = time.slice (1); 
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; 
          time[0] = +time[0] % 12 || 12; 
        }
        return time.join (''); 
      }


} 