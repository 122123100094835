import { NgModule } from '@angular/core';
import { loaderComponent } from '../SharedResources/Components/Loader/loader';







@NgModule({
  imports: [
  ],
  declarations:[
      loaderComponent
  ],
  exports:[
    loaderComponent
  ]
})
export class loaderModule { }
