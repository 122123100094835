import { Routes} from '@angular/router';
import { homeComponent } from './pages/Home/home';
import { othersModule } from './FeaturedModules/others.module';
import { blogModule } from './FeaturedModules/blog.module';
import { productsModule } from './FeaturedModules/products.module';
import { cartModule } from './FeaturedModules/cart.module';
import { paymentModule } from './FeaturedModules/payment.module';
import { profileModule } from './FeaturedModules/profile.module';
import { loginModule } from './FeaturedModules/loginRegistration.module';
import { faqModule } from './FeaturedModules/faq.module';
import { franchiseModule } from './FeaturedModules/franchise.module';



export const AppRoutes : Routes = [
      {
        path:'',
        component:homeComponent,
        pathMatch:'full'

      },
      {
        path: '',
        loadChildren: () => Promise.resolve(othersModule)
      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/blog.module').then(m => m.blogModule)
        loadChildren: () => Promise.resolve(blogModule)

      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/products.module').then(m => m.productsModule)
        loadChildren: () => Promise.resolve(productsModule)

      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/cart.module').then(m => m.cartModule)
        loadChildren: () => Promise.resolve(cartModule)

      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/payment.module').then(m => m.paymentModule)
        loadChildren: () => Promise.resolve(paymentModule)

      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/profile.module').then(m => m.profileModule)
        loadChildren: () => Promise.resolve(profileModule)

      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/loginRegistration.module').then(m => m.loginModule)       
         loadChildren: () => Promise.resolve(loginModule)

      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/faq.module').then(m => m.faqModule)
        loadChildren: () => Promise.resolve(faqModule)
      },
      {
        path: '',
        // loadChildren: () => import('./FeaturedModules/franchise.module').then(m => m.franchiseModule)
        loadChildren: () => Promise.resolve(franchiseModule)
      },
      {
        path: '**',
        redirectTo:'/',
      },
]
