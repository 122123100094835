import { Component,OnInit } from '@angular/core';
import { UserService } from '../../../SharedResources/Services/user.service';
import { errorHandlerService } from '../../../SharedResources/Services/errorHandler.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from "../../../../environments/environment";
import { sharedService } from '../../../SharedResources/Services/shared.service';
import { SeoService } from '../../../SharedResources/Services/seoService';
import { HomeService } from '../../../SharedResources/Services/home.service'; 

declare const $;

@Component({
    templateUrl: './profile.html',
})

export class profileComponent implements OnInit {
    public err:boolean=false
    public load:boolean=false
    public showEditForm:boolean=false
    public showAddress:boolean=false
    public showOrders:boolean=false
    public showOrderDetails:boolean=false;
    public showResetPassword:boolean=false
    public profileDetails:any={}
    public first_name:string;
    public last_name:string;
    public mobile_number:string;
    public display_mobile_number:string;
    public email_id:string;
    public newsletter:boolean;
    public disable_account:boolean;
    public gender:string;
    public dob:any;
    public displayDOB:any;
    public country_id:string;
    public country_name:any;
    public today:any;
    public country:any=[{"id":"0","title":"Saudi Arabia"},{"id":"6","title":"Kuwait"},{"id":"5","title":"Bahrain"}]
    public redirect:boolean=false;
    public order_id:any;
    public LANG:any;
    public old_password:string;
    public new_password:string;
    public confirm_password:string;
    public currency:string="SAR";


    public profileError:any={
        "first_name":false,
        "email_id":false,
        "mobile_number":false,
        "mobile_number_valid":false,
        "email_id_valid":false,
        "country_id":false,
        "gender":false,
        "old_password":false,
        "old_password_valid":false,
        "new_password":false,
        "new_password_valid":false,
        "confirm_password":false,
        "password_match":false
    }
    public subscriptions:Subscription[]=[];

    constructor(private user:UserService,private route:ActivatedRoute,private error:errorHandlerService,private toast:ToastrManager,private router:Router,private shared:sharedService,private seo:SeoService,private home:HomeService){
        this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
                this.changeLanguage();
                this.getUserProfile();         
          }))
          this.subscriptions.push(this.route.params
            .subscribe(
                (params:Params)=>{
                    if(params['id']){
                        this.order_id=atob(params['id'])
                    }
                    if(params['type']){
                      const type=atob(params['type']) 
                      if(type == 'orders'){
                        this.showOrders=true;
                        this.showEditForm=false;
                        this.showAddress=false;
                        this.showOrderDetails=false;
                        this.showResetPassword=false;
                      }
                      else if(type == 'address'){
                        this.showEditForm=false
                        this.showAddress=true;
                        this.showOrders=false;
                        this.showOrderDetails=false
                        this.showResetPassword=false;
                      }else if(type == 'order-details'){
                        this.showDetails(this.order_id)
                      }else if(type == 'reset-password'){
                        this.showEditForm=false
                        this.showAddress=false;
                        this.showOrders=false;
                        this.showOrderDetails=false;
                        this.showResetPassword=true;
                        setTimeout(() => {
                            document.getElementById('password').focus()
                          }, 100);
                      } else{
                        this.router.navigate(['/my-profile'])
                      }
                    }

                }
                ))
                this.router.routeReuseStrategy.shouldReuseRoute = ()=>{
                    return false;
                }
        window.scroll(0,0)
    }

    ngOnInit(){
        this.getUserProfile()
        this.today=this.formatDate(new Date())
        this.changeLanguage()
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
          this.currency="ريال"
        }
        else {
          this.LANG = environment.english_translation;
          this.currency="SAR"
        }
        if(!this.showEditForm && !this.showAddress && !this.showOrders && !this.showOrderDetails){
        this.getSeoDetails()
        }
      }

    getUserProfile(){
        this.subscriptions.push(this.user.getProfile().subscribe(result=>{
            this.profileDetails=result;
            if(this.profileDetails.date_of_birth == "NULL"){
                this.displayDOB="_ _"
            }else{
                this.displayDOB=this.formatDOB(this.profileDetails.date_of_birth)
            }

        }))
    }

    setProfileData(){
        this.first_name=this.profileDetails.first_name
        this.last_name=this.profileDetails.last_name
        this.mobile_number=this.profileDetails.mobile_number
        this.display_mobile_number=`${this.profileDetails.country_code}${this.profileDetails.mobile_number}`
        this.email_id=this.profileDetails.email_id
        this.newsletter=this.profileDetails.newsletter == "1" ? true : false;
        // this.disable_account=this.profileDetails.disable_account == "2" ? true : false;
        this.gender=this.profileDetails.gender
        this.dob=this.profileDetails.date_of_birth
        this.country_id=this.profileDetails.country_id
        this.country_name=this.profileDetails.country_name
        if(this.dob == "NULL"){
            this.dob=""
        }
    }

    setGender(value:string){
        this.gender=value
    }

    cancelEditProfile(){
        this.resetProfileError()
        this.showEditForm=false
        window.scrollTo({
            top:100,
        })
    }

    editProfile(){
        this.setProfileData()
        this.showEditForm=true
        const country=`${this.country_id},${this.country_name}`
        setTimeout(() => {
            $('select').niceSelect();
            $('#country').val(country).niceSelect('update');
            window.scrollTo({
                top:100,
                behavior: 'smooth'
            })
        }, 100);
    }
    update(){
        this.err=false;
        this.resetProfileError();
        this.errorHandler();

        if(!this.err){
            this.load=true
            this.updateProfile()
        }
    }

    updateProfile(){
        const data={
            "customer_id":this.profileDetails.customer_id,
            "country_code":this.profileDetails.country_code,
            "first_name":this.first_name,
            "last_name":this.last_name,
            "mobile_number":this.mobile_number,
            "email_id":this.email_id,
            "newsletter":this.newsletter ? "1" : "0",
            "disable_account":this.disable_account ? "2" : "1",
            "gender":this.gender,
            "country_name":this.country_name,
            "country_id":this.country_id,
            "date_of_birth":this.dob
        }
        this.subscriptions.push(this.user.updateProfile(data).subscribe(result=>{
            if(result['customer_id']){
                this.load=false;
                this.profileDetails=result;
                if(this.disable_account){
                    this.logout();
                    this.toast.successToastr(this.LANG.your_account_is_disabled,"",{position:'top-right',toastTimeout:3000})
                    return
                }
                if(this.profileDetails.date_of_birth == "NULL"){
                    this.displayDOB="_ _"
                }else{
                    this.displayDOB=this.formatDOB(this.profileDetails.date_of_birth)
                }
                this.showEditForm=false
            }else{
                this.load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            this.load=false;
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
              return
            }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
    }

    errorHandler(){
        const country=$('#country').val();
        if(country){
            this.country_id=country.split(',')[0]
            this.country_name=country.split(',')[1]
        }
        if(this.first_name == undefined || this.first_name == ""){
            this.profileError.first_name=true;
            this.err=true;
        }
        if(this.email_id == undefined || this.email_id == ""){
            this.profileError.email_id=true;
            this.err=true;
        }

        if(this.checkEmail(this.email_id) && !this.profileError.email_id){
            this.profileError.email_id_valid=true;	
            this.err=true;
        }

        if(this.country_id == ""  || this.country_id == undefined || this.country == null){
            this.profileError.country_id=true;	
            this.err=true;
        }

        if(this.gender == ""  || this.gender == undefined){
            this.profileError.gender=true;	
            this.err=true;
        }
    }

    checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
    }

    checkMobileNumber(mobile:string){
        if(mobile.length != 10){
            return true
        }
    }

    resetProfileError(){
        this.profileError={
            "first_name":false,
            "email_id":false,
            "mobile_number":false,
            "mobile_number_valid":false,
            "email_id_valid":false,
            "country_id":false,
            "gender":false,
            "old_password":false,
            "old_password_valid":false,
            "new_password":false,
            "new_password_valid":false,
            "confirm_password":false,
            "password_match":false
        }
    }

    formatDOB(date:any){
        const year=date.split('-')[0]
        const month=this.getMonth(date.split('-')[1])
        const day=date.split('-')[2]
        return `${day} ${month},${year}`
    }

    getMonth(index:any){
        const months = ["January","February","March","April","May",
        "June","July","August", "September","October","November","December"];
        return months[index-1]
    }

    formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2)
        month = '0' + month;
        if (day.length < 2)
        day = '0' + day;
        return [year, month, day].join('-');
    }

    showAddressSection(){
        this.showEditForm=false
        this.showAddress=true;
        this.showOrders=false;
        this.showOrderDetails=false
        this.showResetPassword=false;
        window.scroll(0,0)
        this.router.navigate(['/my-profile',{type:btoa("address")}])
    }

    showPasswordSection(){
        this.showEditForm=false
        this.showAddress=false;
        this.showOrders=false;
        this.showOrderDetails=false;
        this.showResetPassword=true;
        this.router.navigate(['/my-profile',{type:btoa("reset-password")}])
        window.scroll(0,0)
    }

    showProfileSection(){
        this.showEditForm=false;
        this.showAddress=false;
        this.showOrders=false;
        this.showOrderDetails=false;
        this.showResetPassword=false;
        window.scroll(0,0)
        this.router.navigate(['/my-profile'])
    }

    showOrderList(){
        this.showOrders=true;
        this.showEditForm=false;
        this.showAddress=false;
        this.showOrderDetails=false;
        this.showResetPassword=false;
        this.router.navigate(['/my-profile',{type:btoa("orders")}])
    }

    showDetails(order_id){
        this.order_id=order_id
        this.showOrderDetails=true
        this.showOrders=false;
        this.showEditForm=false;
        this.showAddress=false;
        this.showResetPassword=false;
    }

    resetPassword(){
        this.err=false;
        this.resetProfileError();
        this.passwordErrorHandler()
        if(!this.err){
            this.load=true
            this.updatePassword()
        }
    }

    updatePassword(){
        const data={
            "old_password":this.user.encryptPassword(this.old_password),
            "password":this.user.encryptPassword(this.new_password),
            "mobile_number":this.profileDetails.mobile_number,
            "country_code":this.profileDetails.country_code
        }
        this.subscriptions.push(this.user.resetPassword(data).subscribe(result=>{
            if(result.status){
                this.load=false;
                this.logout(1);
                this.toast.successToastr(this.LANG.password_changed_successfully,"",{position:'top-right',toastTimeout:3000})
            }else{
                this.load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        }))
    }

    passwordErrorHandler(){
          if(this.old_password == undefined || this.old_password == ""){
            this.profileError.old_password=true;	
            this.err=true;
          }

          if(!this.profileError.old_password && this.checkPassword(this.old_password)){
            this.profileError.old_password_valid=true;	
            this.err=true;
          }
  
          if(this.new_password == "" || this.new_password == undefined){
              this.profileError.new_password=true;	
              this.err=true;
          }

          if(!this.profileError.new_password && this.checkPassword(this.new_password)){
            this.profileError.new_password_valid=true;	
            this.err=true;
        }
        if(!this.profileError.new_password && (this.confirm_password == "" || this.confirm_password == undefined)){
            this.profileError.confirm_password=true;	
            this.err=true;
          }
  
          if(!this.profileError.password_valid && this.new_password != this.confirm_password && !this.profileError.confirm_password){
            this.profileError.password_match=true;	
            this.err=true;
          }
    }

    checkPassword(password:string){
        if(password.length < 8){
            return true
        }
    } 

    ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
    }

    getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("11").subscribe(result=>{
            if(result){
                this.seo.updateMetaTitle(result['response'])
            }
        }))
    }


    logout(type?:number){
        const arabic=localStorage.getItem("arabic")
        this.shared.changeUser(false);
        this.shared.changeToken("");
        this.shared.changeCount("0");
        this.shared.changeWishListCount("0");
        localStorage.clear()
        if(!arabic || arabic == "true"){
            localStorage.setItem("arabic","true") 
        }else{
            localStorage.setItem("arabic","false")
        }
        if(type){
            this.router.navigate(['/login'])
            return
        }
        this.router.navigate(['/'])

    }

} 