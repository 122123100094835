import { Component,OnInit,OnDestroy } from '@angular/core';
import { CartService } from '../../SharedResources/Services/cart.service';
import { errorHandlerService } from '../../SharedResources/Services/errorHandler.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HomeService } from '../../SharedResources/Services/home.service';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { Subscription } from 'rxjs';
import { SeoService } from '../../SharedResources/Services/seoService';

declare const $; 
declare const FB: any;

@Component({
    templateUrl: './cart.html',
})

export class cartComponent implements OnInit,OnDestroy {
    public load:boolean=false
    public cartEmpty:boolean=false
    public cartList:any={};
    public total_product:any;
    public coupon_code:string;
    public currency:string="SAR";
    public coupon_applied:boolean=false;
    public cart_count:string;
    public wishlist_count:string;
    public bestSell_product_list:any=[];
    public logged_in:boolean=false;
    public LANG:any;
    public count:number=0;
    public unsubscribe:Subscription;
    public unsubscribe1:Subscription;
    public review:any=[1,2,3,4,5]
    public subscriptions:Subscription[]=[]
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"




    

    constructor(private cart:CartService,private error:errorHandlerService,private toast:ToastrManager,private home:HomeService,private shared:sharedService,private router:Router,private seo:SeoService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
        if(location.pathname.includes("my-cart")){
            this.changeLanguage();
            this.getCartList();
            this.getBestSellProducts();      
        }
      }))
      this.unsubscribe=this.shared.cartChanged.subscribe(data=>{
        this.getCartList()
      })
      this.unsubscribe1=this.shared.wishlistChanged.subscribe(data=>{
        data.data.wishlist=data.wishlist
      });
      this.subscriptions.push(this.shared.currentCount.subscribe(count=>this.cart_count=count));
      this.subscriptions.push(this.shared.currentWishlistCount.subscribe(count=>this.wishlist_count=count));

      this.subscriptions.push(this.shared.currentUser.subscribe(user=>this.logged_in=user));
      if(localStorage.getItem('saadeddin_logged_in') != undefined){
        this.logged_in=true;
        this.shared.changeUser(true);
      }
      this.changeLanguage()
      window.scroll(0,0)
    }

    ngOnInit(){
      this.getCartList()
      this.getBestSellProducts()
    }

    //Change Language from arabic to english and vice-versa
    changeLanguage() {
      if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
        this.LANG = environment.arabic_translation;
        this.currency="ريال"
      }
      else {
        this.LANG = environment.english_translation;
        this.currency="SAR"
      }
      this.getSeoDetails()
    }

    totalItemTrigger(){
      $(document).ready(function(){
        $(".total_items_trigger").click(function (){
         $(".main_Sub_cart-list").toggleClass("active");
         $(".total_items_trigger").toggleClass("active");
         });
       });
    }
    
    loadSlider(){
      $('.offer_slider').owlCarousel({
        margin: 10,
        dots:false,
        responsiveClass: true,
        responsive: {
          0: {
            items: 2,
            nav: true,
            stagePadding: 0,
          },
          600: {
            items: 3,
            nav: false,
            stagePadding: 0,
          },
          1000: {
            items: 4,
            nav: true,
            margin: 15,
            stagePadding: 0

          }
        }
      })
      this.count=1
    }

    //gets users Cart List
    getCartList(){
      this.subscriptions.push(this.cart.cartList().subscribe(result=>{
          if(result['id']){
            this.cartList=result;
            this.total_product=result['cart_Detail'].length
            this.coupon_code=result['coupon_code']
            this.cartList.cart_Detail.map(data=>{
                  this.setProductsData(data)
            })
            if(this.coupon_code){
              this.coupon_applied=true
            }
            this.totalItemTrigger()
            
          }else if(!result['id']){
            this.load=false
            this.cartEmpty=true;
            this.total_product="0"
            this.shared.changeCount(this.total_product)
            this.totalItemTrigger()
          }
    },respagesError => {
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          return
        }
        this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
    }))
    }


    setProductsData(data){
      data.array_products=[]
      if(data.product_data){
        data.product_data=JSON.parse(data.product_data.replace(/\\/g,""))
        const objectArray = Object.entries(data.product_data);
        objectArray.forEach(([key, value]) => {
          if(key == "shape"){
            data.array_products.push({"key":this.LANG.shape,"value":value})
          }if(key == "total_character_price"){
            data.array_products.push({"key":this.LANG.Character_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_candle_price"){
            data.array_products.push({"key":this.LANG.Candle_Price,"value":`${value} ${this.currency}`})
          }if(key == "image_on_cake_price"){
            data.array_products.push({"key":this.LANG.Image_Price,"value":`${value} ${this.currency}`})
          }if(key == "name_on_cake"){
            data.array_products.push({"key":this.LANG.Name_on_Cake,"value":value})
          }if(key == "name_on_cake_price"){
            data.array_products.push({"key":this.LANG.Name_Price,"value":`${value} ${this.currency}`})
          }if(key == "selected_decoration"){
            data.array_products.push({"key":this.LANG.Selected_Decoration,"value":value})
          }if(key == "decoration_price"){
            data.array_products.push({"key":this.LANG.Decoration_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_flower_price"){
            data.array_products.push({"key":this.LANG.Flower_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_ballon_price"){
            data.array_products.push({"key":this.LANG.Ballon_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_gift_card_pric"){
            data.array_products.push({"key":this.LANG.Gift_Card_Price,"value":`${value} ${this.currency}`})
          }if(key == "gift_card_note"){
            data.array_products.push({"key":this.LANG.Gift_Card_Note,"value":value})
          }
        });
      }
      
    }


    getBestSellProducts(){
      this.bestSell_product_list=[]
      this.subscriptions.push(this.home.bestSellProducts("","").subscribe(result=>{
          if(result){
              this.bestSell_product_list=result['response']
              this.bestSell_product_list.map(data=>{
                data.title=this.decodeHtml(data.title)
              })
              setTimeout(() => {
                  this.loadSlider()
              }, 500);
          }
      }))
  }

    //increases product quantity by 1 unit
    increaseQuantity(product_id,quantity,i){
      this.cartList.cart_Detail[i].load=true
      const newQuantity=parseInt(quantity) + 1
      this.updateCart(product_id,newQuantity,i)
    }

    //decrease product quantity by 1 unit
    decreaseQuantity(product_id,quantity,i){
      this.cartList.cart_Detail[i].load=true
      const newQuantity=parseInt(quantity) - 1
      this.updateCart(product_id,newQuantity,i)
    }

    //delete product from the cart
    deleteCart(product_id,i){
      const newQuantity="0"
      this.updateCart(product_id,newQuantity,i)
    }

    //update cart products
    updateCart(product_id,newQuantity,i){
      const data={
        "cart_detail_id":product_id,
        "quantity":newQuantity.toString()
      }
      this.subscriptions.push(this.cart.updateCart(data).subscribe(result=>{
        if(result['id']){
            this.cartList=result;
            this.total_product=result['cart_Detail'].length
            this.cartList.cart_Detail.map(data=>{
              this.setProductsData(data)
            })
            this.shared.changeCount(this.total_product)
        }else if(result['id'] == ""){
          this.cartList=result;
          this.total_product="0"
          this.shared.changeCount(this.total_product)
          this.cartEmpty=true;
        }
        else{
          this.cartList.cart_Detail[i].load=false
          this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
        }
          // const request = '/product_detail';
          // caches.delete(request);
        
    },respagesError => {
        this.cartList.cart_Detail[i].load=false
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          return
        }
        this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
    }))
    }

    
    applyCoupon(){
          if(this.coupon_code == "" || this.coupon_code == undefined){
              this.toast.warningToastr(this.LANG.enter_valid_coupon,"",{position:'top-right',toastTimeout:3000});
              document.getElementById('coupon').focus();
              return
          }

          this.load=true
          this.couponCode("apply")
    }
    
    removeCoupon(){
      this.load=true
      this.couponCode("remove")
    }

    //apply and remove coupon code
    couponCode(type){
      const data={
        "coupon_code":this.coupon_code
      }
      if(type == "remove"){
        data.coupon_code=""
      }
      this.subscriptions.push(this.cart.couponCode(data).subscribe(result=>{
        if(result['id']){
         this.cartList=result;
         this.total_product=result['cart_Detail'].length;
         this.cartList.cart_Detail.map(data=>{
                  this.setProductsData(data)
          })
         this.coupon_code=result['coupon_code']
         if(type == "apply"){
           this.coupon_applied=true;
           this.toast.successToastr(this.LANG.coupon_code_applied,"",{position:'top-right',toastTimeout:3000})
          }else{
            this.coupon_applied=false;
            this.toast.successToastr(this.LANG.coupon_code_removed,"",{position:'top-right',toastTimeout:3000})
          }
          this.load=false
        }else if(!result.status){
          this.load=false
          this.toast.warningToastr("",result['message'],{position:'top-right',toastTimeout:3000})
        }
      },respagesError => {
        this.load=false
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          return
        }
        this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
    }))
    }


    //add product to cart
    addToCart(data,i){
      if(!this.logged_in){
        this.router.navigate(['/login'])
        return
      }
      if(data.option_flag == "1"){
        this.goToProductDetails(data)
        return
      }
      this.enableLoader(i)
      const data1={
        "product_id":data.id,
        "quantity":"1"
      }
      this.subscriptions.push(this.cart.insertCart(data1).subscribe(result=>{
        if(result.status){
            this.enableLoader(i)
            this.cart_count=result['extra']
            this.shared.changeCount(this.cart_count)
            this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
            this.getCartList()
            this.cartEmpty=false;
        }else{
            this.enableLoader(i)
            this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
  
        }
    },respagesError => {
        this.enableLoader(i)
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          return
        }
        this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
    }))
    }

    enableLoader(i){
        this.bestSell_product_list[i].load=!this.bestSell_product_list[i].load
    }


      //add product to wishlist
      addToWishlist(product_id,i,wishlist,type,id){
        if(!this.logged_in){
            this.router.navigate(['/login'])
            return
          }
        const index="0";
        const size="0";
        this.subscriptions.push(this.cart.insertWishlist(product_id,index,size).subscribe(result=>{
            if(result.status){
                this.updateProduct(wishlist,i,type)
                const count=parseInt(this.wishlist_count) + 1
                this.shared.changeWishListCount(count.toString())
                if(type == "cart"){
                  this.deleteCart(id,i)
                }
                this.toast.successToastr(this.LANG.product_added_to_wishlist,"",{position:'top-right',toastTimeout:3000})
            }else{
              this.updateProduct(wishlist,i,type)
              const count=parseInt(this.wishlist_count) - 1
              this.shared.changeWishListCount(count.toString())
              this.toast.successToastr(this.LANG.product_removed_from_wishlist,"",{position:'top-right',toastTimeout:3000})
          }
        },respagesError => {
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
              return
            }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
      }))
      }

      updateProduct(wishlist,i,type){
              const wish= wishlist == "0" ? '1':'0';
              if(type == "best"){
                this.bestSell_product_list[i].wishlist=wish
              }else{
                this.cartList.cart_Detail[i].wishlist=wish 
              }
      }

      quickView(data){
        this.shared.changeProductDetails(data)
      }

      //share product on fb feeds
      fbShare(){
        FB.ui({
          method: 'feed',
            name: "Saadeddin Pastry shop",
            link: "http://35.154.4.154/saadeddin-new-web/",
            picture: "http://35.154.4.154/saadeddin-new-web/assets/images/logo.png",
            description: "Check out awesome flavours of pastries and other sweets.!"
        })
      }

      goToProductDetails(data,type?:number){
        let product_id;
        if(type){
          product_id=data.product_id
          if(data.product_id == "100001" || data.product_id == "100000" || data.product_id == "100002"){
            return
          }
        }else{
          product_id=data.id
        }
        this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(product_id)])
      }

      decodeHtml(value) {
        let txt = document.createElement('textarea');
        txt.innerHTML = value;
        return txt.value;
      }

      goToCheckout(){
        this.router.navigate(['/checkout'])
      }

      addAdditionalComment(){
        const data={
          "additional_comment":this.cartList.additional_comment,
          "id":this.cartList.id
        }
        this.subscriptions.push(this.cart.addAdditionalComment(data).subscribe(result=>{
            if(result['id']){
                this.cartList=result;
                this.total_product=result['cart_Detail'].length;
                this.cartList.cart_Detail.map(data=>{
                  this.setProductsData(data)
                })
                this.shared.changeCount(this.total_product);
            }else if(!result['id']){
              this.cartList=result;
              this.total_product="0"
              this.shared.changeCount(this.total_product)
              this.cartEmpty=true;
            }
            else{
              this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
              return
            }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
      }

ngOnDestroy(): void {
  this.unsubscribe.unsubscribe()
  this.unsubscribe1.unsubscribe()
  this.subscriptions.map(s => s.unsubscribe());
}


//sets page title,meta title,meta descriptiuon and meta keywords dynamically
getSeoDetails(){
  this.home.getSeoDetals("3").subscribe(result=>{
    if(result){
      this.seo.updateMetaTitle(result['response'])
    }
  })
}

  
    

} 