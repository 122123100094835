import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {AgmCoreModule} from '@agm/core';
import { cartComponent } from '../pages/Cart/cart';
import { loaderModule } from './loader.module';
import { authGuard } from '../SharedResources/Services/authGuard.service';
import { checkoutComponent } from '../pages/Checkout/checkout';
import { wishlistComponent } from '../pages/Wishlist/wishlist';

// import { NgSelectModule,NgSelectConfig } from '@ng-select/ng-select';
// import { ɵs } from '@ng-select/ng-select';
import { filterModule } from './filter.module';




const ChildRoutes: Routes = [

  {
    path: 'my-cart',
    component:cartComponent,
    canActivate : [authGuard]
  },
  {
    path: 'checkout',
    component:checkoutComponent,
    canActivate : [authGuard]
  },
  {
    path: 'my-wishlist',
    component:wishlistComponent,
    canActivate : [authGuard]
  },
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    loaderModule,
    filterModule,
    // NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey:"AIzaSyBxu6gRfla-EaaKmiR7afHqnzxjLbEIi40",
      libraries: ['places']
    }),
  ],
  declarations:[
    cartComponent,
    checkoutComponent,
    wishlistComponent,
    
  ],
  // providers: [NgSelectConfig,ɵs]
  providers: []
})
export class cartModule { }
