import { Component,OnInit} from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from "../../../../environments/environment";
import { sharedService } from '../../../SharedResources/Services/shared.service';
import { HomeService } from '../../../SharedResources/Services/home.service';
import { errorHandlerService } from '../../../SharedResources/Services/errorHandler.service';
import { Router } from '@angular/router';
// import * as S3 from 'aws-sdk/clients/s3';
import { AngularFireStorage } from '@angular/fire/storage';
import { SeoService } from '../../../SharedResources/Services/seoService';
import { Subscription } from 'rxjs';





declare const $;
@Component({
    templateUrl: './careers.html',
    styleUrls:['./careers.css'] 
})
export class careerComponent implements OnInit {
    public err:boolean=false
    public load:boolean=false
    public custom_load:boolean=false
    public category_id:any="0";
    public jobList:any=[];
    public jobList_length:any;
    public careers:any={}
    public country_code: string="+966";
    public full_name:string;
    public position:string;
    public position_id:string;
    public mobile_number:string;
    public email_id:string;
    public message:string="";
    public LANG:any;
    public highlight:boolean=false
    public highlight1:boolean=false
    public cv:any;
    public file:any;
    public file_name:string;
    public file_name1:string;
    public custom_full_name:string;
    public custom_job_title:string;
    public custom_email_id:string;
    public custom_mobile_number:string;
    public custom_cv:string;
    public file1:any;
    public query:any;
    public subscriptions:Subscription[]=[]
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"
    public country_list:any=[]
    public city_list:any=[]
    public district_list:any=[]
    public nationality_list:any=[]
    public qualification_list:any=[]
    public country_id:string=""
    public city_id:string=""
    public district_id:string=""
    public nationality_id:string=""
    public qualification_id:string=""
    public dob:any;



    public careerError:any={
        "full_name":false,
        "full_name_valid":false,
        "email_id":false,
        "email_id_valid":false,
        "mobile_number":false,
        "mobile_number_valid":false,
        "nature_of_enquiry":false,
        "message":false,
        "country":false,
        "city":false,
        "district":false,
        "nationality":false,
        "qualification":false,
        "dob":false,
    }

    public customError:any={
        "full_name":false,
        "full_name_valid":false,
        "job_title":false,
        "email_id":false,
        "email_id_valid":false,
        "mobile_number":false,
        "mobile_number_valid":false,
    }

    constructor(private home:HomeService,private storage: AngularFireStorage,private router:Router,private error:errorHandlerService,private toast:ToastrManager,private shared:sharedService,private seo:SeoService){
        this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("careers")){
                this.changeLanguage();
                this.getCareers()
            }
          }))
    }

    ngOnInit(){
        this.changeLanguage();
        this.getCareers()
        window.scroll(0,0)
    }

    //Change Language from arabic to english and vice-versa
    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
    }

    closeForm(){
        $(".career_form").removeClass('show');
        $(".career_form_overlay").removeClass('show');
    }

    openForm(role:string,id:number){
        this.clearData()
        this.clearCustomData()
        $(".career_form").addClass('show');
        $(".career_form_overlay").addClass('show');
        this.highlight=false
        this.position=role;
        this.position_id=id.toString()
    }      

    //gets Dynamic careers data
    getCareers(){
        this.subscriptions.push(this.home.careers().subscribe(result=>{
            if(result){
                this.careers=result;
                this.country_list=this.careers.country;
                this.nationality_list=this.careers.nationality;
                this.qualification_list=this.careers.qualification;
                if(this.careers.jobcategory.length > 0){
                    this.jobList=this.careers.recentjobs;
                    if(this.careers.recentjobs){
                        this.jobList_length=this.careers.recentjobs.length
                    }else{
                        this.jobList_length=0
                    }
                }
            }
        }))
    }

    changeCategory(category_id:any){
        if(this.category_id == category_id){
            return
        }

        this.category_id=category_id

        if(category_id == '0'){
            this.jobList=this.careers.recentjobs
            return
        }

        if(this.careers.recentjobs){
            this.jobList=this.careers.recentjobs.filter(data=>{
                return data.category_id==this.category_id
            })
        }

        this.query=""
    }

    errorHandler(){
        let reg=/^([a-zA-Zء-ي]{3,}\s[a-zA-Zء-ي]{1,}'?-?[a-zA-Zء-ي]{2,}\s?([a-zA-Zء-ي]{1,})?)/
        if(this.full_name == undefined || this.full_name == ""){
            this.careerError.full_name=true;
            this.err=true;
        }    

        if(!this.careerError.full_name && !reg.test(this.full_name)){
            this.careerError.full_name_valid=true;
            this.err=true;
        }
        if(this.email_id == undefined || this.email_id == ""){
            this.careerError.email_id=true;
            this.err=true;
        }

        if(this.mobile_number == ""  || this.mobile_number == undefined){
            this.careerError.mobile_number=true;	
            this.err=true;
        }

        if(this.country_id == ""  || this.country_id == undefined){
            this.careerError.country=true;	
            this.err=true;
        }

        if(this.city_id == ""  || this.city_id == undefined){
            this.careerError.city=true;	
            this.err=true;
        }

        if(this.district_id == ""  || this.district_id == undefined){
            this.careerError.district=true;	
            this.err=true;
        }

        if(this.nationality_id == ""  || this.nationality_id == undefined){
            this.careerError.nationality=true;	
            this.err=true;
        }

        if(this.qualification_id == ""  || this.qualification_id == undefined){
            this.careerError.qualification=true;	
            this.err=true;
        }

        if(this.dob == ""  || this.dob == undefined || this.dob == null){
            this.careerError.dob=true;	
            this.err=true;
        }

       

        if(!this.careerError.mobile_number && this.checkMobileNumber(this.mobile_number)){
            this.careerError.mobile_number_valid=true;	
            this.err=true;
        }

        if(this.checkEmail(this.email_id) && !this.careerError.email_id){
            this.careerError.email_id_valid=true;	
            this.err=true;
        }

        
    }

    errorHandler1(){
        let reg=/^([a-zA-Z]{3,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
        if(this.custom_full_name == undefined || this.custom_full_name == ""){
            this.customError.full_name=true;
            this.err=true;
        }

        if(!this.customError.full_name && !reg.test(this.custom_full_name)){
            this.customError.full_name_valid=true;
            this.err=true;
        }

        if(this.custom_job_title == undefined || this.custom_job_title == ""){
            this.customError.job_title=true;
            this.err=true;
        }

        
        if(this.custom_email_id == undefined || this.custom_email_id == ""){
            this.customError.email_id=true;
            this.err=true;
        }

        if(this.custom_mobile_number == ""  || this.custom_mobile_number == undefined){
            this.customError.mobile_number=true;	
            this.err=true;
        }

       

        if(!this.customError.mobile_number && this.checkMobileNumber(this.custom_mobile_number)){
            this.customError.mobile_number_valid=true;	
            this.err=true;
        }

        if(this.checkEmail(this.custom_email_id) && !this.customError.email_id){
            this.customError.email_id_valid=true;	
            this.err=true;
        }

        
    }

    checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
    }

    checkMobileNumber(mobile:string){
        if(mobile.length < 9 || mobile.length > 10){
            return true
        }
    }

    onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
    }

    sendData(){
        this.err=false;
        this.resetError()
        this.errorHandler()
        if(!this.err){
            if(this.file1 == ""  || this.file1 == undefined){
                this.toast.warningToastr(this.LANG.Please_select_a_file_to_upload,"",{position:'top-right',toastTimeout:3000})
                return
            }
            this.load=true
            const data={
                "name":this.full_name,
                "phone":this.mobile_number,
                "email":this.email_id,
                "message":this.message,
                "role":this.position,
                "cv":this.cv,
                "career_id":this.position_id,
                "country_id":this.country_id, 
                "city_id":this.city_id,
                "district_id":this.district_id,
                "nationality_id":this.nationality_id,
                "dob":this.dob,
                "qualification_id":this.qualification_id,
            }
            this.applyJob(data,this.file1)
        }
    }

    sendCustomData(){
        this.err=false;
        this.resetCustomError()
        this.errorHandler1()
        if(!this.err){
            if(this.file == ""  || this.file == undefined){
                this.toast.warningToastr(this.LANG.Please_select_a_file_to_upload,"",{position:'top-right',toastTimeout:3000})
                return
            }
            this.custom_load=true
            const data={
                "name":this.custom_full_name,
                "phone":this.custom_mobile_number,
                "email":this.custom_email_id,
                "message":this.message,
                "role":this.custom_job_title,
                "cv":this.cv,
                "career_id":"0",
            }
            this.applyJob(data,this.file)
        }
    }


    //stores user enetered details
    applyJob(data,file){
        this.uploadImage(file,callback=>{
            if(callback){
            data['cv']=`${this.cv}`
            this.subscriptions.push(this.home.applyJob(data).subscribe(result=>{
                if(result.status){
                    this.closeForm()
                    this.clearData()
                    this.clearCustomData()
                    this.toast.successToastr(this.LANG.thank_you_for_your_response,"",{position:'top-right',toastTimeout:3000}) 
                    this.load=false;
                    this.custom_load=false;
                }else{
                    this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000}) 
                    this.load=false;
                    this.custom_load=false;
                }
            },respagesError => {
                this.load=false
                const error = this.error.getError(respagesError);
                this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
            }))
            }
        });
    }

    resetError(){
        this.careerError={
            "full_name":false,
            "full_name_valid":false,
            "email_id":false,
            "email_id_valid":false,
            "mobile_number":false,
            "mobile_number_valid":false,
            "nature_of_enquiry":false,
            "message":false,
            "country":false,
            "city":false,
            "nationality":false,
            "dob":false,
            "qualification":false,
        }
    }

    resetCustomError(){
        this.customError={
            "full_name":false,
            "full_name_valid":false,
            "email_id":false,
            "email_id_valid":false,
            "mobile_number":false,
            "mobile_number_valid":false,
        }
    }

    clearData(){
            this.resetError()
            this.full_name=""
            this.mobile_number=""
            this.email_id=""
            this.message=""
            this.position=""
            this.position_id=""
            this.file=""
            this.file1=""
            this.cv=""
            this.file_name=""
            this.file_name1=""
            this.city_id=""
            this.country_id=""
            this.district_id=""
            this.nationality_id=""
            this.qualification_id=""
            this.dob=""
            this.city_list=[]
            this.district_list=[]
            this.load=false;
    }

    clearCustomData(){
        this.resetCustomError()
        this.custom_full_name=""
        this.custom_job_title=""
        this.custom_email_id=""
        this.custom_mobile_number=""
        this.file=""
        this.file1=""
        this.custom_cv=""
        this.file_name=""
        this.file_name1=""
        this.load=false;
    }

    openFile(id:any){
        const new_id=`#${id}`
        $(new_id).click()
    }

    //file reader
    fileChanged(event,type:number){
        var files = event.target.files;
        const file = files[0];
        if(files.length > 1){
            this.toast.warningToastr(this.LANG.cannot_add_more_files,"",{position:'top-right',toastTimeout:3000});
            return
        }
        var ext = file.name.split('.').pop();
        if(ext != "pdf" && ext != "docx" && ext != "doc"){
            this.toast.warningToastr(this.LANG.you_can_only_add_pdf_doc_or_docx_file,"",{position:'top-right',toastTimeout:3000});
            return
        }

        if(files.length > 0 && file) {
             if(type == 1){
                 this.file=file
                 this.file_name=this.file.name
                 return
             }
             this.file1=file
             this.file_name1=this.file1.name
        }
    }

    onDrop(event: any,type:number) {
        event.preventDefault();
        event.stopPropagation();
        let dt = event.dataTransfer
        let files = dt.files
        if(files.length > 1){
            this.toast.warningToastr(this.LANG.cannot_add_more_files,"",{position:'top-right',toastTimeout:3000});
            return
        }
        const file=files[0]
        var ext = file.name.split('.').pop();
        if(ext != "pdf" && ext != "docx" && ext != "doc"){
            this.toast.warningToastr(this.LANG.you_can_only_add_pdf_doc_or_docx_file,"",{position:'top-right',toastTimeout:3000});
            return
        }

        if(files.length > 0 && file) {
            if(type == 1){
                this.file=file
                this.file_name=this.file.name;
                return
            }
            this.file1=file
            this.file_name1=this.file1.name
        }

    }

    _handleReaderLoaded(type:number,readerEvt) {
        var binaryString = readerEvt.target.result;
        var base64textString=btoa(binaryString);
        this.cv=base64textString
        if(type==1){
            this.highlight1=true;
            return
        }
        this.highlight=true;
    }
    
    onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }
    
    onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    goToDescription(id:any){
        this.router.navigate(['/job-description/'+btoa(id)])
    }

    //upload files to S3 bucket
    // uploadFile(file:any,callback) {
    //     const contentType = file.type;
    //     const bucket = new S3(
    //           {
    //               accessKeyId: 'AKIA25UTM6GGGUCLVI4Q',
    //               secretAccessKey: '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',
    //               region: 'ap-south-1'
    //           }
    //       );
    //       const params = {
    //           Bucket: 'saddedin-images',
    //           Key:file.name,
    //           Body: file,
    //           ACL: 'public-read',
    //           ContentType: contentType
    //       };
    //       bucket.upload(params, (err, data) =>{
    //           if (err) {
    //               this.load=false;
    //               this.custom_load=false;
    //               this.toast.warningToastr('There was an error uploading your file',"", );
    //               callback(false)
    //               return false;
    //           }
    //           this.cv=data.Location;
    //           callback(true)
    //       });
    // }

    uploadImage(file:any,callback){
        var n = Date.now()+file.name;
        if(file){
        const filePath = `Resumes/${n}`;
        const fileRef = this.storage.ref(filePath);
        fileRef.put(file)
        .then((snapshot) => {
        this.subscriptions.push(fileRef.getDownloadURL().subscribe((downloadURL)=> {
        this.cv=downloadURL
        callback(true)
    }));
      }).catch(err=>{
          console.log(err)
      })
        
    }
    }

    //sets page title,meta title,meta descriptiuon and meta keywords dynamically
    getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("2").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }


      countryChanged(id:string){
        this.city_list=[]
        let index = this.country_list.findIndex( data => data.id == id)
        this.country_id=id;
        this.city_list=this.country_list[index].city;
      }

      cityChanged(id:string){
        this.district_list=[]
        let index = this.city_list.findIndex( data => data.id == id)
        this.city_id=id;
        this.district_list=this.city_list[index].district;
      }

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }
    

}