import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router'; 

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'حلويات سعد الدين';


  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event.urlAfterRedirects)
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }

    });
   
    // localStorage.setItem('myVariable', 'false');

  }

}
