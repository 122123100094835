import {Injectable} from '@angular/core';
import {apiServiceComponent} from './api.service';
import * as md5 from 'md5'



@Injectable({providedIn: 'root'})
export class UserService{
    private url : string = "";

	constructor(private api : apiServiceComponent){
    }

    encryptPassword(password : any ){
		return md5(password);
	}

    registration(data){
        this.url="registration";
		return this.api.post(this.url,data); 
    }

    checkMobile(data){
        this.url="check_mobile";
		return this.api.post(this.url,data); 
    }

    login(data){
        this.url="login";
		return this.api.post(this.url,data); 
    }

    sendOTP(data){
        this.url="sms_otp";
		return this.api.post(this.url,data); 
    }

    verifyOTP(data){
        this.url="verify_otp";
		return this.api.post(this.url,data); 
    }


    getProfile(){
        this.url="get_profile";
        let query="";
		return this.api.get(this.url,query); 
    }


    updateProfile(data){
        this.url="get_profile";
		return this.api.post(this.url,data); 
    }

    getUserAddress(){
        this.url="user_address_list";
        let query="?type=1";
		return this.api.get(this.url,query);
    }

    getSelectedAddress(){
        this.url="user_address_list";
        let query="";
		return this.api.get(this.url,query);
    }

    getAddressDetails(id){
        this.url="user_address_list";
        let query="?id="+id;
		return this.api.get(this.url,query);
    }

    saveAddress(data){
        this.url = "user_address";
        return this.api.post(this.url, data);
    }

    checkCity(data){
        this.url="check_city_by_latlong";
        return this.api.post(this.url, data);
    }

    resetPassword(data){
        this.url="change_password";
		return this.api.post(this.url,data); 
    }
}