import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../SharedResources/Services/user.service';
import { errorHandlerService } from '../../SharedResources/Services/errorHandler.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { environment } from "../../../environments/environment";
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';

declare const $;

@Component({
    templateUrl: './registration.html',
})

export class registrationComponent implements OnInit {
    public err:boolean=false;
    public load:boolean=false;
    public logged_in:boolean=false;
    public first_name:string;
    public last_name:string;
    public mobile_number:string;
    public display_mobile_number:string;
    public country_code:string;
    public email_id:string;
    public password:string;
    public confirm_password:string;
    public terms:boolean=true;
    public token:string;
    public LANG:any;
    public subscriptions:Subscription[]=[]

    public registrationError:any={
        "first_name":false,
        "email_id":false,
        "mobile_number":false,
        "mobile_number_valid":false,
        "email_id_valid":false,
        "password":false,
        "password_valid":false,
        "confirm_password":false,
        "password_match":false,
        "confirm_password_valid":false,
    }


    constructor(private router:Router,private user:UserService,private error:errorHandlerService,private toast:ToastrManager,private shared:sharedService,private seo:SeoService,private home:HomeService){
        this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("registration")){
                this.changeLanguage();
            }
          }))
        this.shared.currentToken.subscribe(token=>this.token=token);
        const a=this.router.getCurrentNavigation().extras.state;
        if(a){
            this.mobile_number=a.mobile_number
            this.country_code=a.country_code
            this.display_mobile_number=`${this.country_code}${this.mobile_number}`
        }else{
            this.router.navigate(['/'])
        }
        if(localStorage.getItem('saadeddin_logged_in') != undefined){
            this.logged_in=true;
            this.shared.changeUser(true);
          }
          this.changeLanguage()
          window.scroll(0,0)
    }

    ngOnInit(){
        this.toggleTermsCondition();
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
      }

    toggleTermsCondition(){
        $(".check-btn").click(function () {
            $(this).toggleClass("checked");
        });
    }

    termsCondition(){
        this.terms=!this.terms;
    }

    register(){
        this.err=false;
        this.resetRegistrationError()
        this.errorHandler()
        if(!this.err){
            if(!this.terms){
                this.toast.warningToastr(this.LANG.please_accept_the_terms_condition_policy,"",{position:'top-right',toastTimeout:3000})
                return
            }
            this.load=true
            this.registerUser()
        }
    }

    errorHandler(){
        if(this.first_name == undefined || this.first_name == ""){
            this.registrationError.first_name=true;
            this.err=true;
        }
        if(this.email_id == undefined || this.email_id == ""){
            this.registrationError.email_id=true;
            this.err=true;
        }
        if(this.password == ""  || this.password == undefined){
            this.registrationError.password=true;	
            this.err=true;
        }

        if(this.checkEmail(this.email_id) && !this.registrationError.email_id){
            this.registrationError.email_id_valid=true;	
            this.err=true;
        }

        if(!this.registrationError.password && this.checkPassword(this.password)){
            this.registrationError.password_valid=true;	
            this.err=true;
        }

        if(!this.registrationError.password && this.confirm_password == ""){
            this.registrationError.confirm_password=true;	
            this.err=true;
        }

        if(this.confirm_password == "" || this.confirm_password == undefined){
            this.registrationError.confirm_password=true;	
            this.err=true;
        }

        if(!this.registrationError.password_valid && this.password != this.confirm_password && !this.registrationError.confirm_password){
          this.registrationError.password_match=true;	
          this.err=true;
        }
    }

    registerUser(){
        const login_token=`${this.mobile_number}w8s7dghrteys`
        const data={
            "country_code":this.country_code,
            "first_name":this.first_name,
            "last_name":this.last_name,
            "mobile_number":this.mobile_number,
            "email_id":this.email_id,
            "password":this.user.encryptPassword(this.password),
            "login_token":this.user.encryptPassword(login_token),
            "register_from":"1"
        }
        this.subscriptions.push(this.user.registration(data).subscribe(result=>{
            if(result['customer_id']){
                this.load=false;
                this.logged_in=true;
                this.shared.changeUser(true);
                this.shared.changeCount(result['cart'] || "0");
                this.shared.changeWishListCount(result['wishlist'] || "0");
                this.shared.changeToken(result['token']);
                localStorage.setItem("saadeddin_logged_in", "1");
                localStorage.setItem("name", result['first_name']+" "+result['last_name']);
                localStorage.setItem("email_id", result['email_id']);
                localStorage.setItem("mobile_number", result['mobile_number']);
                localStorage.setItem("country_code", result['country_code']);
                localStorage.setItem("token", result['token']);
                this.toast.successToastr("",this.LANG.registration_successfull_welcome+result['first_name'],{position:'top-right',toastTimeout:3000})
                this.router.navigate(['/'])
              }else if(!result.status){
                  this.load=false
                  this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
              }
      },respagesError => {
          this.load=false;
          const error = this.error.getError(respagesError);
          if(error == "Gateway timeout"){
            return
          }
          this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
      }))
        
    }
    resetRegistrationError(){
        this.registrationError={
            "first_name":false,
            "email_id":false,
            "email_id_valid":false,
            "password":false,
            "mobile_number":false,
            "mobile_number_valid":false,
            "password_valid":false,
            "confirm_password":false,
            "password_match":false,
            "confirm_password_valid":false,
        }
    }

    checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
    }

    checkPassword(password:string){
        if(password.length < 8){
            return true
        }
    } 

    checkMobileNumber(mobile:string){
        if(mobile.length != 10){
            return true
        }
    }

    onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }

     openTermsConditions(){ 
        $("#terms-conditions").modal("show")
    }

    openPrivacyPolicy(){ 
        $("#privacy-policy").modal("show")
    }

    getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("15").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }


} 