import { Component,OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from "../../../../environments/environment";
import { sharedService } from 'src/app/SharedResources/Services/shared.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/SharedResources/Services/blog.service';
import { SeoService } from 'src/app/SharedResources/Services/seoService';




 
@Component({
    templateUrl: './blogDetails.html',
})

export class blogDetailsComponent implements OnInit { 
    public id:any;
    public blogs:any=[];
    public newBlogs:any=[];
    public blog_details:any={};
    public blog_loaded:boolean=false;
    public LANG:any;
    public subscriptions:Subscription[]=[];
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"
    

    constructor(private shared:sharedService,private blog:BlogService,private router:Router,private route:ActivatedRoute,private seo:SeoService){
      this.subscriptions.push(this.route.params
        .subscribe(
            (params:Params)=>{
              this.id=atob(params['id'])
            }
            ))
            this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("blog-details")){
                this.changeLanguage();
            }
          }))
          this.subscriptions.push(this.shared.getBlogList().subscribe(data=>{
            this.blogs=data;
            this.getNewBlogs([...this.blogs]);
          }))
          this.changeLanguage();
          window.scroll(0,0) 
    }

    ngOnInit(){
      if(this.blogs.length == 0){
        this.getBlogList();
      }
      this.getBlogDetails();
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    getBlogDetails(){
      this.subscriptions.push(this.blog.blogDetails(this.id).subscribe(result=>{
          if(result){
            this.blog_loaded=true;
            this.blog_details=result.response;
            this.blog_details.date=this.formatDate(this.blog_details.date);
            const data={
              "page_title": this.blog_details.page_title,
              "meta_title": this.blog_details.meta_title,
              "meta_keywords": this.blog_details.meta_keywords,
              "meta_description": this.blog_details.meta_description,
              "h1_tag": this.blog_details.h1_tag,
              "h2_tag": this.blog_details.h2_tag,            
            }
            const data1:any={
              "title":this.blog_details.title,
              "description":this.blog_details.description,
              "image":this.blog_details.image,
              "url": window.location.href
            }
            this.seo.updateMetaTitle(data);
            this.seo.setData(data1); 
          }else{
            this.blog_loaded=true;
          }
      }))
    }

    getBlogList(){
      this.subscriptions.push(this.blog.blogList().subscribe(result=>{
        if(result){
          this.blogs=result.response;
          this.blogs.map(data=>{
            data.date=this.formatDate(data.date);
          })
          this.shared.changeBlogList(this.blogs);
          this.getNewBlogs([...this.blogs]);
        }
      }))
    }

    formatDate(value:any){
      const date=value.split(" ")[0]
      const day=date.split("-")[2]
      const month=date.split("-")[1]
      const year=date.split("-")[0]
        return `${this.getMonth(month)} ${day},${year}`
        // return `${this.getMonth(month)} ${day},${year} ${time}`
    }

    getMonth(index){
      const months = ["January","February","March","April","May",
      "June","July","August", "September","October","November","December"];
      return months[index-1]
    }


    //Change Language from arabic to english and vice-versa
    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
      }



      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }

      getNewBlogs(newBlogs){
        this.newBlogs=[]
        let index=newBlogs.findIndex(data=>{return data.id == this.id})
        newBlogs.splice(index,1);
        this.newBlogs=newBlogs
      }

      goToBlogDetails(blog){
        this.router.navigate(['/blog-details/'+btoa(blog.id)]);
        this.id=blog.id;
        this.getBlogDetails();
        this.getNewBlogs([...this.blogs]);
      }


} 