import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { franchiseComponent } from '../pages/Franchise/franchise';
import { loaderModule } from './loader.module';
import { FormsModule } from '@angular/forms';
import {AgmCoreModule} from '@agm/core';






const ChildRoutes: Routes = [
    
      {
        path:'franchise',
        component:franchiseComponent
      }
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    CommonModule,
    loaderModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey:"AIzaSyBxu6gRfla-EaaKmiR7afHqnzxjLbEIi40",
      libraries: ['places']
    }),
  ],
  declarations:[  
    franchiseComponent  
  ]
})
export class franchiseModule { } 
