import { Component,OnInit, EventEmitter, Output } from '@angular/core';
import { OrderService } from '../../../../SharedResources/Services/order.service';
import { Subscription } from 'rxjs';
import { sharedService } from '../../../../SharedResources/Services/shared.service';
import { environment } from "../../../../../environments/environment";
import { Router } from '@angular/router';
import { HomeService } from '../../../../SharedResources/Services/home.service';
import { SeoService } from '../../../../SharedResources/Services/seoService';


@Component({
    selector:'profile-orders',
    templateUrl: './profile-orderList.html',
})

export class profileOrderListComponent implements OnInit {
        public load:boolean=false
        public index:string="0"
        public size:string="5"
        public currency:string="SAR";
        public orderList:any=[]
        public newOrderList:any=[]
        public orderLength:any;
        public count:any;
        public LANG:any;
        public subscriptions:Subscription[]=[]

        @Output() showOrderDetails: EventEmitter<any> = new EventEmitter<any>();

    constructor(private order:OrderService,private shared:sharedService,private router:Router,private home:HomeService,private seo:SeoService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            this.changeLanguage();
            this.getOrderList()
        }))
        this.changeLanguage()
        this.router.routeReuseStrategy.shouldReuseRoute = ()=>{
            return false;
        }
        window.scroll(0,0)
    }

    ngOnInit(){
        this.getOrderList()
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
          this.currency="ريال"
        }
        else {
          this.LANG = environment.english_translation;
          this.currency="SAR"
        }
        this.getSeoDetails()
      }

    getOrderList(){
      this.subscriptions.push(this.order.orderList(this.index,this.size).subscribe(result=>{
            if(result){
                this.orderList=result['response']
                this.orderLength=result['response'].length
                this.count=result['count']
                this.orderList.map(data=>{
                    data.date_added=this.formatDate(data.date_added)
                })
            }
        }))
    }

    loadMoreOrders(){
        this.load=true
        const newIndex=parseInt(this.index)+1
        this.index=newIndex.toString()
        this.getMoreOrders()
    }

    getMoreOrders(){
      this.subscriptions.push(this.order.orderList(this.index,this.size).subscribe(result=>{
            if(result){
                this.newOrderList=result['response']
                this.newOrderList.map(data=>{
                    data.date_added=this.formatDate(data.date_added)
                })
                this.orderList=[...this.orderList,...this.newOrderList]
                this.load=false;
                this.count=result['count']
            }
        }))
    }

    orderDetails(order_id){
        this.router.navigate(['/my-profile',{type:btoa("order-details"),id:btoa(order_id)}])
    }

    ngOnDestroy(): void {
      this.subscriptions.map(s => s.unsubscribe());
    }

    goToProductDetails(data){
        if(data.product_id == "100002" || data.product_id == "100000" || data.product_id == "100001"){
            return
          }
        this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.product_id)])
      }

      formatDate(value:any){
        const date=value.split(" ")[0]
        const day=date.split("-")[2]
        const month=date.split("-")[1]
        const year=date.split("-")[0]
          return `${this.getMonth(month)} ${day},${year}`
      }
    
      getMonth(index){
        const months = ["January","February","March","April","May",
        "June","July","August", "September","October","November","December"];
        return months[index-1]
      }

      getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("14").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

  

} 