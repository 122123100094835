import { Component,OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { environment } from "../../../environments/environment";
import { sharedService } from '../../SharedResources/Services/shared.service';
import { OrderService } from '../../SharedResources/Services/order.service';
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';

declare const $;
declare const FB;

@Component({
    templateUrl: './thankyou.html',
})

export class thankyouComponent implements OnInit {
    public order_id:any;
    public expected_delivery_date:any;
    public LANG:any;
    public data:any;
    public message:string;
    public message_heading:string;
    public image:any;
    public payment_status:boolean;
    public page_loaded:boolean=false;
    public subscriptions:Subscription[]=[];
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"

    
    

    constructor(private router:Router,private route:ActivatedRoute,private loc: LocationStrategy,private shared:sharedService,private order:OrderService,private seo:SeoService,private home:HomeService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
        if(location.pathname.includes("thank-you")){
            this.changeLanguage();
        }
      }))
      this.changeLanguage()
      this.subscriptions.push(this.route.queryParams
            .subscribe(
            (params:Params)=>{
              if(params['id']){
                this.order_id=atob(params['id'])
                this.data=atob(params['id'])
                this.getOrderDetails(this.order_id)
              }
            }
            ))
        window.scroll(0,0)
        const a=this.router.getCurrentNavigation().extras.state;
        if(a){
            this.order_id=a.order_id
            this.expected_delivery_date=this.formatDate(a.expected_delivery_date);
            this.message=this.LANG.your_order_placed_successfully
            this.message_heading=this.LANG.thank_you
            this.image=this.assets_path+"/images/tick.png"
            this.page_loaded=true
        } 
        
        else if(!a && !this.data){
            this.router.navigate(['/'])
        }
        history.pushState(null, null, window.location.href);  
        this.loc.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
    }

    ngOnInit(){

    }

    changeLanguage() {
      if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
        this.LANG = environment.arabic_translation;
        this.message=this.LANG.your_order_placed_successfully
        this.message_heading=this.LANG.thank_you
      }
      else {
        this.LANG = environment.english_translation;
        this.message=this.LANG.your_order_placed_successfully
        this.message_heading=this.LANG.thank_you
        
      }

      this.getSeoDetails()
    }

    fbShare(){
        FB.ui({
          method: 'feed',
            name: "Saadeddin Pastry shop",
            link: window.location.origin,
            picture: this.assets_path+"/images/logo.png",
            description: "Check out awesome flavours of pastries and other sweets.!"
        })
      }

      popuptweet(){
        let text="Check out awesome flavors of pastries and other sweets.!"
        let urll=encodeURIComponent(window.location.origin);
        let url = 'https://twitter.com/intent/tweet?text='+text+'&url='+urll+'&hashtags=SaadedinPastries';
        let newwindow=window.open(url,'height=500,width=520,top=200,left=300,resizable');
      if (window.focus) {
        newwindow.focus()
      }
    }
    

    openReturnPolicy(){ 
        $("#return-policy").modal("show")
    }

    

    ngOnDestroy(){
      history.pushState(null, null, window.location.href);  
        this.loc.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
        this.subscriptions.map(s => s.unsubscribe());
    }

    formatDate(value:any){
      const time=`${value.split(" ")[1]} ${value.split(" ")[2]}`
      const date=value.split(" ")[0]
      const day=date.split("-")[2]
      const month=date.split("-")[1]
      const year=date.split("-")[0]
        return `${this.getMonth(month)} ${day},${year}`
        // return `${this.getMonth(month)} ${day},${year} ${time}`
    }

    getMonth(index){
      const months = ["January","February","March","April","May",
      "June","July","August", "September","October","November","December"];
      return months[index-1]
    }

    getSeoDetails(){
      this.subscriptions.push(this.home.getSeoDetals("17").subscribe(result=>{
        if(result){
          this.seo.updateMetaTitle(result['response'])
        }
      }))
    }

    getOrderDetails(order_id){
      this.subscriptions.push(this.order.orderDetails(order_id).subscribe(result=>{
            if(result){ 
                this.order_id=result.order_id;
                this.expected_delivery_date=this.formatDate(result['expected_delivery_date']);
                this.message=`${this.LANG.your_payment_for_order_id} ${this.order_id} ${this.LANG.was_successfull}`;
                this.message_heading=this.LANG.thank_you
                this.image=this.assets_path+"/images/tick.png";
                this.shared.changeCount("0")
                this.page_loaded=true
            }
        }))
    }

 

} 