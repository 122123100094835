import { Component,OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { sharedService } from '../../SharedResources/Services/shared.service';
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/SharedResources/Services/header.service';
declare const $:any

@Component({
    templateUrl: './franchise.html', 
})

export class franchiseComponent implements OnInit { 
    public LANG:any;
    public load:boolean=false;
    public err:boolean=false;
    public storeList:any=[];
    public franchise_details:any={};
    public faq:any=[];
    public gallery:any=[];
    public models:any=[];
    public page1:any=[];
    public page3:any=[];
    public model_details:any={};
    public country_code:string="+966"
    public name:string;
    public mobile_number:string;
    public email_id:string;
    public location:string="";
    public location2:string="";
    public no_of_branch:string="";
    public country:string="";
    public city:string="";
    public investment:string="";
    public expected_time:string="";
    public business_franchisor  :string="";
    public food_sector:string="";
    public owned_shop:string="";
    public employment_status:string="";
    public work:string="";
    public invest:string="";
    public showMap:boolean=true
    public icon: any="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets/images/icons/map_logo.png";
    public zoom: number = 5;
    public openedWindow : any;
    public latitude = 24.774265;
    public longitude = 46.738586;
    public store_name:string;
    public store_mobile_number:string;
    public store_address:string;
    public previous:any;
    public error:any={
      "name":false,
      "mobile_number":false,
      "mobile_number_valid":false,
      "email_id":false,
      "email_id_valid":false,
      "location":false,
      "no_of_branch":false,   
      "country":false,        	
      "city":false,        	
      "investment":false,
      "expected_time":false,  
      "business_franchisor":false,  
      "food_sector":false,     
      "owned_shop":false,      
      "employment_status":false,   
      "work":false,	
      "invest":false,
    }
    public subscriptions:Subscription[]=[];
    country_code_list:any=
 [
      { country: "Afghanistan", code: "+93" },
      { country: "Albania", code: "+355" },
      { country: "Algeria", code: "+213" },
      { country: "Andorra", code: "+376" },
      { country: "Angola", code: "+244" },
      { country: "Antigua and Barbuda", code: "+1-268" },
      { country: "Argentina", code: "+54" },
      { country: "Armenia", code: "+374" },
      { country: "Australia", code: "+61" },
      { country: "Austria", code: "+43" },
      { country: "Azerbaijan", code: "+994" },
      { country: "Bahamas", code: "+1-242" },
      { country: "Bahrain", code: "+973" },
      { country: "Bangladesh", code: "+880" },
      { country: "Barbados", code: "+1-246" },
      { country: "Belarus", code: "+375" },
      { country: "Belgium", code: "+32" },
      { country: "Belize", code: "+501" },
      { country: "Benin", code: "+229" },
      { country: "Bhutan", code: "+975" },
      { country: "Bolivia", code: "+591" },
      { country: "Bosnia and Herzegovina", code: "+387" },
      { country: "Botswana", code: "+267" },
      { country: "Brazil", code: "+55" },
      { country: "Brunei", code: "+673" },
      { country: "Bulgaria", code: "+359" },
      { country: "Burkina Faso", code: "+226" },
      { country: "Burundi", code: "+257" },
      { country: "Cambodia", code: "+855" },
      { country: "Cameroon", code: "+237" },
      { country: "Canada", code: "+1" },
      { country: "Cape Verde", code: "+238" },
      { country: "Central African Republic", code: "+236" },
      { country: "Chad", code: "+235" },
      { country: "Chile", code: "+56" },
      { country: "China", code: "+86" },
      { country: "Colombia", code: "+57" },
      { country: "Comoros", code: "+269" },
      { country: "Congo", code: "+242" },
      { country: "Costa Rica", code: "+506" },
      { country: "Croatia", code: "+385" },
      { country: "Cuba", code: "+53" },
      { country: "Cyprus", code: "+357" },
      { country: "Czech Republic", code: "+420" },
      { country: "Denmark", code: "+45" },
      { country: "Djibouti", code: "+253" },
      { country: "Dominica", code: "+1-767" },
      { country: "Dominican Republic", code: "+1-809" },
      { country: "East Timor", code: "+670" },
      { country: "Ecuador", code: "+593" },
      { country: "Egypt", code: "+20" },
      { country: "El Salvador", code: "+503" },
      { country: "Equatorial Guinea", code: "+240" },
      { country: "Eritrea", code: "+291" },
      { country: "Estonia", code: "+372" },
      { country: "Ethiopia", code: "+251" },
      { country: "Fiji", code: "+679" },
      { country: "Finland", code: "+358" },
      { country: "France", code: "+33" },
      { country: "Gabon", code: "+241" },
      { country: "Gambia", code: "+220" },
      { country: "Georgia", code: "+995" },
      { country: "Germany", code: "+49" },
      { country: "Ghana", code: "+233" },
      { country: "Greece", code: "+30" },
      { country: "Grenada", code: "+1-473" },
      { country: "Guatemala", code: "+502" },
      { country: "Guinea", code: "+224" },
      { country: "Guinea-Bissau", code: "+245" },
      { country: "Guyana", code: "+592" },
      { country: "Haiti", code: "+509" },
      { country: "Honduras", code: "+504" },
      { country: "Hungary", code: "+36" },
      { country: "Iceland", code: "+354" },
      { country: "India", code: "+91" },
      { country: "Indonesia", code: "+62" },
      { country: "Iran", code: "+98" },
      { country: "Iraq", code: "+964" },
      { country: "Ireland", code: "+353" },
      { country: "Israel", code: "+972" },
      { country: "Italy", code: "+39" },
      { country: "Jamaica", code: "+1-876" },
      { country: "Japan", code: "+81" },
      { country: "Jordan", code: "+962" },
      { country: "Kazakhstan", code: "+7" },
      { country: "Kenya", code: "+254" },
      { country: "Kiribati", code: "+686" },
      { country: "Korea, North", code: "+850" },
      { country: "Korea, South", code: "+82" },
      { country: "Kosovo", code: "+383" },
      { country: "Kuwait", code: "+965" },
      { country: "Kyrgyzstan", code: "+996" },
      { country: "Laos", code: "+856" },
      { country: "Latvia", code: "+371" },
      { country: "Lebanon", code: "+961" },
      { country: "Lesotho", code: "+266" },
      { country: "Liberia", code: "+231" },
      { country: "Libya", code: "+218" },
      { country: "Liechtenstein", code: "+423" },
      { country: "Lithuania", code: "+370" },
      { country: "Luxembourg", code: "+352" },
      { country: "Macedonia", code: "+389" },
      { country: "Madagascar", code: "+261" },
      { country: "Malawi", code: "+265" },
      { country: "Malaysia", code: "+60" },
      { country: "Maldives", code: "+960" },
      { country: "Mali", code: "+223" },
      { country: "Malta", code: "+356" },
      { country: "Marshall Islands", code: "+692" },
      { country: "Mauritania", code: "+222" },
      { country: "Mauritius", code: "+230" },
      { country: "Mexico", code: "+52" },
      { country: "Micronesia", code: "+691" },
      { country: "Moldova", code: "+373" },
      { country: "Monaco", code: "+377" },
      { country: "Mongolia", code: "+976" },
      { country: "Montenegro", code: "+382" },
      { country: "Morocco", code: "+212" },
      { country: "Mozambique", code: "+258" },
      { country: "Myanmar", code: "+95" },
      { country: "Namibia", code: "+264" },
      { country: "Nauru", code: "+674" },
      { country: "Nepal", code: "+977" },
      { country: "Netherlands", code: "+31" },
      { country: "New Zealand", code: "+64" },
      { country: "Nicaragua", code: "+505" },
      { country: "Niger", code: "+227" },
      { country: "Nigeria", code: "+234" },
      { country: "Norway", code: "+47" },
      { country: "Oman", code: "+968" },
      { country: "Pakistan", code: "+92" },
      { country: "Palau", code: "+680" },
      { country: "Panama", code: "+507" },
      { country: "Papua New Guinea", code: "+675" },
      { country: "Paraguay", code: "+595" },
      { country: "Peru", code: "+51" },
      { country: "Philippines", code: "+63" },
      { country: "Poland", code: "+48" },
      { country: "Portugal", code: "+351" },
      { country: "Qatar", code: "+974" },
      { country: "Romania", code: "+40" },
      { country: "Russia", code: "+7" },
      { country: "Rwanda", code: "+250" },
      { country: "Saint Kitts and Nevis", code: "+1-869" },
      { country: "Saint Lucia", code: "+1-758" },
      { country: "Saint Vincent and the Grenadines", code: "+1-784" },
      { country: "Samoa", code: "+685" },
      { country: "San Marino", code: "+378" },
      { country: "Sao Tome and Principe", code: "+239" },
      { country: "Saudi Arabia", code: "+966" },
      { country: "Senegal", code: "+221" },
      { country: "Serbia", code: "+381" },
      { country: "Seychelles", code: "+248" },
      { country: "Sierra Leone", code: "+232" },
      { country: "Singapore", code: "+65" },
      { country: "Slovakia", code: "+421" },
      { country: "Slovenia", code: "+386" },
      { country: "Solomon Islands", code: "+677" },
      { country: "Somalia", code: "+252" },
      { country: "South Africa", code: "+27" },
      { country: "South Sudan", code: "+211" },
      { country: "Spain", code: "+34" },
      { country: "Sri Lanka", code: "+94" },
      { country: "Sudan", code: "+249" },
      { country: "Suriname", code: "+597" },
      { country: "Swaziland", code: "+268" },
      { country: "Sweden", code: "+46" },
      { country: "Switzerland", code: "+41" },
      { country: "Syria", code: "+963" },
      { country: "Taiwan", code: "+886" },
      { country: "Tajikistan", code: "+992" },
      { country: "Tanzania", code: "+255" },
      { country: "Thailand", code: "+66" },
      { country: "Togo", code: "+228" },
      { country: "Tonga", code: "+676" },
      { country: "Trinidad and Tobago", code: "+1-868" },
      { country: "Tunisia", code: "+216" },
      { country: "Turkey", code: "+90" },
      { country: "Turkmenistan", code: "+993" },
      { country: "Tuvalu", code: "+688" },
      { country: "Uganda", code: "+256" },
      { country: "Ukraine", code: "+380" },
      { country: "United Arab Emirates", code: "+971" },
      { country: "United Kingdom", code: "+44" },
      { country: "United States", code: "+1" },
      { country: "Uruguay", code: "+598" },
      { country: "Uzbekistan", code: "+998" },
      { country: "Vanuatu", code: "+678" },
      { country: "Vatican City", code: "+39-06" },
      { country: "Venezuela", code: "+58" },
      { country: "Vietnam", code: "+84" },
      { country: "Yemen", code: "+967" },
      { country: "Zambia", code: "+260" },
      { country: "Zimbabwe", code: "+263" },
      // More countries...
    ];
    
    
    // Example usage: Get country code for a specific country
   
    


    
    

    constructor(private shared:sharedService,private seo:SeoService,private header:HeaderService,private home:HomeService,private toast:ToastrManager,private router:Router){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("franchise")){
              this.getFranchiseDetails();
              this.showMap=false;
              this.getStoreList();
              this.changeLanguage();
            }
          }))
          this.changeLanguage();
          window.scroll(0,0)
    }

    ngOnInit(){
      this.getFranchiseDetails();
      this.getStoreList();
	  }

    countryCodeChange(){
      // console.log(this.country_code);
      
    }
	
	    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
      }



      getFranchiseDetails(){
        this.subscriptions.push(this.home.franchise().subscribe(result=>{
          if(result){
            this.franchise_details=result;
            this.page1=result['page1'];
            this.page3=result['page3'];
            this.gallery=result['gallery'];
            this.models=result['models'];
            this.faq=result['faq'];
            if(this.models.length > 0){
              this.model_details=this.models[0]
            }
          }
        }))
      }

      getStoreList(){
        this.subscriptions.push(this.header.storeList().subscribe(result=>{
              if(result){
                  this.storeList=result['response'];
                  if(this.storeList.length == 0){
                    this.showMap=false
                  }else{
                      this.showMap=true
                  }
              }
          }))
      }

      clickedMarker(m:any,infowindow:any) {
        this.store_name=m.title
        this.store_mobile_number=m.telephone
        this.store_address=m.address
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
      }

    isInfoWindowOpen(id) {
      return this.openedWindow == id; 
    } 

      next(id:string){
        if(id){
          const element=document.getElementById(id) as HTMLElement;
          element.classList.remove('show','active');
        }
        window.scroll(0,0)
      }
      

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }


      viewModelDetails(data){
        if(data.id == this.model_details.id){
          return
        }
        this.model_details=data;
        window.scrollTo({
          behavior:"smooth",
          top:($("#model-details").position().top - 150)
        })
      }

      applyFranchise(){
        this.err=false;
        this.resetError();
        this.errorHandler();
        if(!this.err){
          this.load=true;
          const data={
            "name":this.name,
            "email":this.email_id,
            "phone":this.mobile_number,
            "location":this.location2,
            "no_of_branch":this.no_of_branch,   
            "country":this.country,        	
            "city":this.city,        	
            "investment":this.investment,
            "expected_time":this.expected_time,  
            "business_franchisor":this.business_franchisor,  
            "food_sector":this.food_sector,     
            "owned_shop":this.owned_shop,      
            "employment_status":this.employment_status,   
            "work":this.work,	
            "invest":this.invest,
          }
          // console.log(data);
          // this.load=false;
          
          this.subscriptions.push(this.home.applyFanchise(data).subscribe(result=>{
              if(result.status){
                this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000}) 
                this.load=false; 
                this.router.navigate(['/']);
                return             
              }
              this.load=false;
              this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000}) 
          }))
        }
      }

      resetError(){
        this.error={
          "name":false,
          "mobile_number":false,
          "mobile_number_valid":false,
          "email_id":false,
          "email_id_valid":false,
          "location":false,
          "no_of_branch":false,   
          "country":false,        	
          "city":false,        	
          "investment":false,
          "expected_time":false,  
          "business_franchisor":false,  
          "food_sector":false,     
          "owned_shop":false,      
          "employment_status":false,   
          "work":false,	
          "invest":false,
        }
      }

      errorHandler(){
        if(this.name==undefined || this.name==''){
          this.error.name  =true;
          this.err=true;	
        }
        if(this.email_id==undefined || this.email_id==''){
          this.error.email_id  =true;
          this.err=true;	
        }
        if(this.mobile_number==undefined || this.mobile_number==''){
          this.error.mobile_number  =true;
          this.err=true;	
        }
        if(this.location2==undefined || this.location2==''){
          this.error.location  =true;
          this.err=true;	
        }
        if(this.no_of_branch==undefined || this.no_of_branch==''){
          this.error.no_of_branch  =true;
          this.err=true;	
        }
        if(this.country==undefined || this.country==''){
          this.error.country  =true;
          this.err=true;	
        }
        if(this.city==undefined || this.city==''){
          this.error.city  =true;
          this.err=true;	
        }
        // if(this.location==undefined || this.location==''){
        //   this.error.location  =true;
        //   this.err=true;	
        // }
        if(this.investment==undefined || this.investment==''){
          this.error.investment  =true;
          this.err=true;	
        }
        if(this.expected_time==undefined || this.expected_time==''){
          this.error.expected_time  =true;
          this.err=true;	
        }
        if(this.food_sector==undefined || this.food_sector==''){
          this.error.food_sector  =true;
          this.err=true;	
        }
        if(this.business_franchisor==undefined || this.business_franchisor==''){
          this.error.business_franchisor  =true;
          this.err=true;	
        }
        if(this.owned_shop==undefined || this.owned_shop==''){
          this.error.owned_shop  =true;
          this.err=true;	
        }
        if(this.employment_status==undefined || this.employment_status==''){
          this.error.employment_status  =true;
          this.err=true;	
        }
        if(this.work==undefined || this.work==''){
          this.error.work  =true;
          this.err=true;	
        }
        if(this.invest==undefined || this.invest==''){
          this.error.invest  =true;
          this.err=true;	
        }
        if(this.checkEmail(this.email_id) && !this.error.email_id){
          this.error.email_id_valid=true;	
          this.err=true;
        }
        // this.mobileNumberValidation();
      }

      mobileNumberValidation(){
        const re1=/^([5]{1}[0-9]*)$/;
        if(this.error.mobile_number == false && !re1.test(this.mobile_number)){
          this.error.mobile_number_valid=true;
          this.err=true;	
        }

        if(this.error.mobile_number == false && re1.test(this.mobile_number) ){
          this.error.mobile_number_valid=true;
          this.err=true;	
        }
      }

      checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
      }

      onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }




} 