import { Component,OnInit } from '@angular/core';
import { CartService } from '../../SharedResources/Services/cart.service';
import { errorHandlerService } from '../../SharedResources/Services/errorHandler.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';

declare const $;

@Component({
    templateUrl: './wishlist.html',
})

export class wishlistComponent implements OnInit {
    public size:string="100";
    public index:string="0";
    public load:boolean=false
    public wishlistLoaded:boolean=false
    public wislist:any=[];
    public cart_count:string;
    public wishlist_count:any;
    public product_id:any;
    public currency:string="SAR";
    public LANG:any;
    public subscriptions:Subscription[]=[]

    constructor(private cart:CartService,private router:Router,private error:errorHandlerService,private toast:ToastrManager,private shared:sharedService,private seo:SeoService,private home:HomeService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
        if(location.pathname.includes("my-wishlist")){
            this.changeLanguage();
            this.getWishlist();
        }
      }))
        this.subscriptions.push(this.shared.currentCount.subscribe(count=>this.cart_count=count));
        this.subscriptions.push(this.shared.currentWishlistCount.subscribe(count=>this.wishlist_count=count));
        this.changeLanguage();
        window.scroll(0,0)
    }

    ngOnInit(){
        this.getWishlist();
    }

    changeLanguage() {
      if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
        this.LANG = environment.arabic_translation;
        this.currency="ريال"
      }
      else {
        this.LANG = environment.english_translation;
        this.currency="SAR"
      }
      this.getSeoDetails()
    }

    getWishlist(){
      this.subscriptions.push(this.cart.wishList(this.index,this.size).subscribe(result=>{
            if(result){
                this.wislist=result['response']
                const count=this.wislist.length
                this.shared.changeWishListCount(count)
                this.wishlistLoaded=true
                setTimeout(() => {
                    $('select').niceSelect();
                }, 300);
            }
        }))
    }

    deleteWishlist(id){
        this.load=true
        this.subscriptions.push(this.cart.insertWishlist(id,this.index,this.size).subscribe(result=>{
            if(result){
                this.wislist=result['response']
                const count=this.wislist.length
                this.shared.changeWishListCount(count)
                this.toast.successToastr(this.LANG.product_removed_from_wishlist,"",{position:'top-right',toastTimeout:3000})
                this.load=false
            }else{
                this.load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            this.load=false
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
              return
            }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
      }))
    }

    addToCart(data){
        const data1={
          "product_id":data.id,
          "quantity":"1"
        }


        if(data.option_flag == "1"){
          this.goToProductDetails(data)
          return
        }
        this.subscriptions.push(this.cart.insertCart(data1).subscribe(result=>{
          if(result.status){
              this.cart_count=result['extra']
              this.shared.changeCount(this.cart_count)
              this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
          }else{
            this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
          }
      },respagesError => {
          const error = this.error.getError(respagesError);
          if(error == "Gateway timeout"){
            return
          }
          this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
    }))
      }

      goToProductDetails(data){
        this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)])
      }

      getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("18").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }

    

} 