import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { loaderModule } from './loader.module';
import { thankyouComponent } from '../pages/ThankYou/thankyou';
import { authGuard } from '../SharedResources/Services/authGuard.service';
import { paymentFailed } from '../pages/Payment/payment-failed';




const ChildRoutes: Routes = [
  {
    path: 'payment-failed',
    component:paymentFailed,
    canActivate : [authGuard]
  },
  {
    path: 'thank-you',
    component:thankyouComponent,
    canActivate : [authGuard]
  },

  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    loaderModule
  ],
  declarations:[
    thankyouComponent,
    paymentFailed
  ]
})
export class paymentModule { }
