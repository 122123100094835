import { Component,OnInit } from '@angular/core';
@Component({
    selector:'loader',
    templateUrl: './loader.html',
})

export class loaderComponent implements OnInit {

    ngOnInit(){
    }

   

}  