import { Component, OnInit,OnDestroy } from '@angular/core';
import { ActivatedRoute,Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeService } from '../../../SharedResources/Services/home.service';
import { CartService } from '../../../SharedResources/Services/cart.service';
import { errorHandlerService } from '../../../SharedResources/Services/errorHandler.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { sharedService } from '../../../SharedResources/Services/shared.service';
import { environment } from "../../../../environments/environment";
import { SeoService } from '../../../SharedResources/Services/seoService';

declare const $;
declare const FB;

@Component({
    templateUrl: './productListing.html',
    })

    export class productListingComponent implements OnInit,OnDestroy {
        public offer_id:any;
        public category_id:any;
        public category:string;
        public url_category:string;
        public subcategory_id:any;
        public subcategory:string;
        public url_subcategory:string;
        public banner_image:any;
        public index:any="0";
        public size:string="20";
        public sort:string="2";
        public currency:string="SAR";
        public productsLoaded:boolean=false;
        public filters:any=[];
        public count:any;
        public product_list:any=[];
        public bestSell_product_list:any=[];
        public pages:any=[];
        public cart_count:string;
        public wishlist_count:string;
        public logged_in:boolean=false;
        public LANG:any;
        public subscriptions:Subscription[]=[]


        


        constructor(private home:HomeService,private cart:CartService,private shared:sharedService,private error:errorHandlerService,private route:ActivatedRoute,private router:Router,private toast:ToastrManager,private seo:SeoService) {
            this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
                this.pages=[]
                this.changeLanguage();
                this.loadFilters();
          }))
          this.subscriptions.push(this.route.params
            .subscribe(
                (params:Params)=>{
                    if(params['category']){
                        this.url_category=params['category']
                        this.category_id=atob(params['category_id'])
                    }
                    if(params['subcategory']){
                        this.url_subcategory=params['subcategory']
                        this.subcategory_id=atob(params['subcategory_id'])
                    }

                    if(params['offer_id']){
                        this.offer_id=atob(params['offer_id'])
                    }
                }
                ))
                this.subscriptions.push(this.shared.currentCount.subscribe(count=>this.cart_count=count));
                this.subscriptions.push(this.shared.currentWishlistCount.subscribe(count=>this.wishlist_count=count));
                this.subscriptions.push(this.shared.currentUser.subscribe(user=>this.logged_in=user));
                if(localStorage.getItem('saadeddin_logged_in') != undefined){
                this.logged_in=true;
                this.shared.changeUser(true);
                }
                this.subscriptions.push(this.shared.wishlistChanged.subscribe(data=>{
                    data.data.wishlist=data.wishlist
                }));
                this.changeLanguage()
                window.scroll(0,0)
        }

        ngOnInit() {
            this.loadFilters()
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        }

        changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
          this.currency="ريال"
        }
        else {
          this.LANG = environment.english_translation;
          this.currency="SAR"
        }
            $("#sort").niceSelect("destroy");
      }

        filterButtons(){
            $('select').niceSelect();
            $(".list_nice").on('change', 'select', ()=> {
                var sortBy = $("#sort").val();
                this.sort=sortBy
                this.getProducts()
              });

              $(".show-product").on('change', 'select', ()=> {
                this.size= $("#show").val();
                this.index="0";
                this.pages=[]
                this.getSortedProducts();
                $("#show1").val(this.size).niceSelect('update');
              });
              $(".show-product1").on('change', 'select', ()=> {
                this.size= $("#show1").val();
                this.index="0";
                this.pages=[]
                this.getSortedProducts();
                $("#show").val(this.size).niceSelect('update');
              });
        }

        getProductList(){
            const data:any={
                "category_id":this.category_id,
                "sub_category_id":this.subcategory_id,
                "index":this.index,
                "size":this.size,
                "sort":this.sort
            }
            if(this.offer_id){
                data.offer_id=this.offer_id
            }
            this.subscriptions.push(this.home.productListing(data).subscribe(result=>{
                if(result){
                    this.product_list=result['response'];
                    this.seo.updateMetaTitle(result['seo'])
                    this.count=result['count']
                    this.subcategory=result['banner_info'].title
                    this.banner_image=result['banner_info'].image
                    this.productsLoaded=true
                    this.product_list.map(data=>{
                        data.title=this.decodeHtml(data.title)
                    })

                    const totalPage = Math.ceil(parseInt(this.count)/parseInt(this.size));
                    for(let i=1;i<=totalPage;i++){
                        this.pages.push({"page":i})
                    }
                    if(this.product_list.length>0){
                        this.url_category=this.product_list[0].url_category
                        this.category=this.product_list[0].category
                    }
                    window.scroll(0,0)
                }
            }))
        }

        loadFilters(){
            this.subscriptions.push(this.home.filters(this.category_id,this.subcategory_id).subscribe(result=>{
                if(result){
                    this.filters=result['response']
                    this.filters.map(data=>{
                        data.count=data.sub_category.length
                    })
                    this.getProductList()
                    this.getBestSellProducts()
                    setTimeout(() => {
                        this.filterButtons()                       
                    }, 500);
                }
            }))
        }

        getBestSellProducts(){
            this.subscriptions.push(this.home.bestSellProducts(this.category_id,this.subcategory_id).subscribe(result=>{
                if(result){
                    this.bestSell_product_list=result['response']
                }
            }))
        }

        getProducts(){
            const data:any={
                "category_id":this.category_id,
                "sub_category_id":this.subcategory_id,
                "index":this.index,
                "size":this.size,
                "sort":this.sort
            }
            if(this.offer_id){
                data.offer_id=this.offer_id
            }
            this.subscriptions.push(this.home.productListing(data).subscribe(result=>{
                if(result){
                    this.product_list=result['response'];
                    this.seo.updateMetaTitle(result['seo'])
                    this.product_list.map(data=>{
                        data.title=this.decodeHtml(data.title)
                    })
                    window.scroll(0,0)
                    
                }
            }))
        }

        getSortedProducts(){
            const data:any={
                "category_id":this.category_id,
                "sub_category_id":this.subcategory_id,
                "index":this.index,
                "size":this.size,
                "sort":this.sort
            }
            if(this.offer_id){
                data.offer_id=this.offer_id
            }
            this.subscriptions.push(this.home.productListing(data).subscribe(result=>{
                if(result){
                    this.pages=[]
                    this.product_list=[]
                    this.product_list=result['response'];
                    this.seo.updateMetaTitle(result['seo'])
                    this.count=result['count']
                    this.subcategory=result['banner_info'].title
                    this.productsLoaded=true
                    this.product_list.map(data=>{
                        data.title=this.decodeHtml(data.title)
                    })
                    const totalPage = Math.ceil(parseInt(this.count)/parseInt(this.size));
                    for(let i=1;i<=totalPage;i++){
                        this.pages.push({"page":i})
                    }
                    window.scroll(0,0)       
                }
            }))
        }

        filterProducts(category,category_id,subcategory_id){
            if(subcategory_id == this.subcategory_id){
                return
            }
            this.subcategory_id=subcategory_id
            this.category_id=category_id
            this.category=category
            this.pages=[] 
            this.sort="1"
            this.index="0"
            this.size="20"
            $("#show1").val(this.size).niceSelect('update');
            $("#show").val(this.size).niceSelect('update');
            $("#sort").val(this.sort).niceSelect('update');
            this.getProductList()
        }

        nextPage(index:string){
            if(index == this.index){
                return
            }
            this.index=index.toString()
            this.getProducts()
        }

        goToProductDetails(data){
            this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)])
        }

        goToProductDetailsNew(data){
            return '/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)
        }


        addToWishlist(product_id,i,wishlist){
            if(!this.logged_in){
                this.router.navigate(['/login'])
                return
              }
            const index="0";
            const size="0";
            this.subscriptions.push(this.cart.insertWishlist(product_id,index,size).subscribe(result=>{
                if(result.status){
                    this.updateProductList(i,"add")
                    const count=parseInt(this.wishlist_count) + 1
                    this.shared.changeWishListCount(count.toString())
                    this.toast.successToastr(this.LANG.product_added_to_wishlist,"",{position:'top-right',toastTimeout:3000})
                }else{
                    this.updateProductList(i,"remove")
                    const count=parseInt(this.wishlist_count) - 1
                    this.shared.changeWishListCount(count.toString())
                    this.toast.successToastr(this.LANG.product_removed_from_wishlist,"",{position:'top-right',toastTimeout:3000})
                }
            },respagesError => {
                const error = this.error.getError(respagesError);
                if(error == "Gateway timeout"){
                  return
                }
                this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
          }))
        }

        updateProductList(i,type){
            if(type == "add"){
                this.product_list[i].wishlist="1"
            }else{
                this.product_list[i].wishlist="0"
            }
        }

        addToCart(data,i,arr){
            if(!this.logged_in){
                this.router.navigate(['/login'],{state:{product_id:data.product_id}})
                return
              }

              if(data.option_flag == "1"){
                this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)])
                return
              }
            if(arr = "product_list"){
                this.product_list[i].load=true
            }
            const data1={
              "product_id":data.id,
              "quantity":"1"
            }
            this.subscriptions.push(this.cart.insertCart(data1).subscribe(result=>{
              if(result.status){
                  this.product_list[i].load=false
                  this.cart_count=result['extra']
                  this.shared.changeCount(this.cart_count)
                  this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
              }else{
                this.product_list[i].load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
              }
          },respagesError => {
              this.product_list[i].load=false
              const error = this.error.getError(respagesError);
              if(error == "Gateway timeout"){
                return
              }
              this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
          }

          quickView(data){
            this.shared.changeProductDetails(data)
          }
       
          showMobFilter(){
            $(".listing_overlay").toggleClass("active");
            $("#accordion").toggleClass("active");
            $(".listing_filter_toggle").toggleClass("active");
          }
          fbShare(){
            FB.ui({
              method: 'feed',
                name: "Saadeddin Pastry shop",
                link: window.location.origin,
                picture: window.location.origin+"/assets/images/logo.png",
                description: "Check out awesome flavours of pastries and other sweets.!"
            })
          }

          goToProductList(){
            this.router.navigate(['/'+this.url_category+'/'+btoa(this.category_id)])
          }

          ngOnDestroy(): void {
            this.subscriptions.map(s => s.unsubscribe());
          }

          decodeHtml(value) {
            let txt = document.createElement('textarea');
            txt.innerHTML = value;
            return txt.value;
        }



    

    }