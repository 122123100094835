import { Component,OnInit,OnDestroy } from '@angular/core';
import { HomeService } from '../../SharedResources/Services/home.service';
import { errorHandlerService } from '../../SharedResources/Services/errorHandler.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CartService } from '../../SharedResources/Services/cart.service';
import { Router } from '@angular/router';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { environment } from "../../../environments/environment";
import { Subscription, timer } from 'rxjs';
import { SeoService } from '../../SharedResources/Services/seoService';

declare const $;
declare const FB: any;

@Component({
    templateUrl: './home.html', 
    styleUrls: ['./home.css']
})

export class homeComponent implements OnInit,OnDestroy {
    public load:boolean=false;
    public email_id:string;
    public banners:any=[];
    public section1:any=[];
    public special_offer:any=[];
    public random_products:any=[];
    public offer_banner:any=[];
    public food_salads:any=[];
    public cake:any=[];
    public cake1:any=[];
    public cart_count:string;
    public wishlist_count:string;
    public currency:string="SAR"
    public product_details:any={}
    public logged_in:boolean=false;
    public token:string;
    public cake_builder:any;
    public LANG:any;
    public ar_selected:boolean;
    public steps_image:any="assets/images/customization-ar.png";
    public unsubscribe:Subscription;
    public schema:any= {}
    public load_schema:boolean= false;
    public subscriptions:Subscription[]=[]
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"

    public popupImageDisplay



    
      constructor(private home:HomeService,private error:errorHandlerService,private cart:CartService,private toast:ToastrManager,private router:Router,private shared:sharedService,private seo:SeoService){
        this.subscriptions.push(this.shared.currentCount.subscribe(count=>this.cart_count=count))
        this.subscriptions.push(this.shared.currentWishlistCount.subscribe(count=>this.wishlist_count=count));
        this.subscriptions.push(this.shared.currentUser.subscribe(user=>this.logged_in=user));
        this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
          this.router.navigate(['/'])
        }))
        if(localStorage.getItem('saadeddin_logged_in') != undefined){
          this.logged_in=true;
          this.shared.changeUser(true);
        }
        this.subscriptions.push(this.shared.currentToken.subscribe(token=>{
          this.token=token
          this.cake_builder="https://www.saadeddin.com/cake-builder/home/"+this.token;
          
        }));
        this.unsubscribe=this.shared.wishlistChanged.subscribe(data=>{
          data.data.wishlist=data.wishlist
        });
        window.scroll(0,0)
        if(localStorage.getItem('token') != undefined){
          this.token=localStorage.getItem('token');
          this.cake_builder="https://www.saadeddin.com/cake-builder/home/"+this.token;
      }
        this.router.routeReuseStrategy.shouldReuseRoute = ()=>{
          return false;
      }
      this.changeLanguage()
      
      }

    ngOnInit(){
        this.getHomePage1()
        this.getSeoDetails()
        this.setCanocialUrl()
        // localStorage.setItem('myVariable','false')
        // this.openModal()
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
            this.LANG = environment.arabic_translation;
            this.currency="ريال"
            this.ar_selected=true;
            // this.steps_image=this.assets_path+"/images/steps-ar.png"
            this.steps_image="assets/images/customization-ar.png"

        }
        else {
            this.LANG = environment.english_translation;
            this.currency="SAR"
            this.ar_selected=false;
            // this.steps_image=this.assets_path+"/images/steps.png"
            this.steps_image="assets/images/customization.png"

          }
    }

    loadSlider(){
        $('#banner_thumb_links_slider').owlCarousel({
        margin:30,
        dots:false,
        responsiveClass:true,
        navText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
        //navText: ["<img src='assets/images/prev.png'>","<img src='assets/images/next.png'>"],
        responsive:{
            0:{
                items:3,
                margin:10,
                nav:true,
                stagePadding: 0, 
                loop:true,
            },
            600:{
                items:3,
                stagePadding: 0,
                margin:10,
                nav:false,
            },
            1000:{
                items:5,
                nav:true,
            }
        }
        })
        $('#special-slider').owlCarousel({
            margin: 10,
            dots:false,
            responsiveClass: true,
            navText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
            responsive: {
              0: {
                items: 2,
                nav: true,
                stagePadding: 0,
              },
              600: {
                items: 3,
                nav: false,
                stagePadding: 0,
              },
              1000: {
                items: 4,
                nav: true,
                margin: 15
              }
            }
          })
    }

    loadSlider1(){
      $('.offer_slider').owlCarousel({
          margin: 10,
          dots:false,
          responsiveClass: true,
          navText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
          responsive: {
            0: {
              items: 2,
              nav: true,
              stagePadding: 0,
            },
            600: {
              items: 3,
              nav: false,
              stagePadding: 0,
            },
            1000: {
              items: 4,
              nav: true,
              margin: 15
            }
          }
        })
  }
  popUpImage:any

    getHomePage1(){
      this.subscriptions.push(this.home.homePage1().subscribe(result=>{
        if(result){
          this.banners=result['banner']
          this.section1=result['section1'] || [];
          this.special_offer=result['section2'] || [];
          this.popUpImage = result['popup'] || []
          this.setSchema()   
          const t = timer(500);
          this.subscriptions.push(t.subscribe(() => { 
              this.loadSlider();
              const time = timer(1000);
              this.subscriptions.push(time.subscribe(() => { 
                this.getHomePage2();
              })); 
            }))
      }
      
      const myVariable = localStorage.getItem('myVariable')
      console.log(myVariable);
      
      if(this.popUpImage.length != 0  && localStorage.getItem('myVariable')=='false'){
      this.openModal()
      // localStorage.setItem('myVariable', 'false');
      }
      },respagesError => {
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          const arabic=localStorage.getItem("arabic")
          localStorage.clear()
          if(!arabic || arabic == "true"){
              localStorage.setItem("arabic","true")
            }else{
              localStorage.setItem("arabic","false")
            }
          setTimeout(() => {
            this.getHomePage1()
          }, 1000);
        return
        }
        this.toast.errorToastr(error,this.LANG.error,{position:'top-right',toastTimeout:300000})
    }))
    }

    getHomePage2(){
      this.subscriptions.push(this.home.homePage2().subscribe(result=>{
        if(result){
          this.random_products=result['section3'] || [];
          this.offer_banner=result['section4'] || [];
          this.food_salads=result['section5'] || [];
          this.cake=result['section6'] || []
          this.cake1=result['section7'] || []        
          setTimeout(() => {
              this.loadSlider1();
          }, 500);
      }
      },respagesError => {
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          const arabic=localStorage.getItem("arabic")
          localStorage.clear()
          if(!arabic || arabic == "true"){
              localStorage.setItem("arabic","true")
            }else{
              localStorage.setItem("arabic","false")
            }
          setTimeout(() => {
            this.getHomePage2()
          }, 1000);
        return
        }
        this.toast.errorToastr(error,this.LANG.error,{position:'top-right',toastTimeout:3000})
    }))
    }

    addToWishlist(product_id,i,arr,wishlist){
      if(!this.logged_in){
        this.router.navigate(['/login'])
        return
      }
      const index="0";
      const size="0";
      this.subscriptions.push(this.cart.insertWishlist(product_id,index,size).subscribe(result=>{
          if(result.status){
              this.updateList(i,wishlist,arr)
              const count=parseInt(this.wishlist_count) + 1
              this.shared.changeWishListCount(count.toString())
              this.toast.successToastr(this.LANG.product_added_to_wishlist,"",{position:'top-right',toastTimeout:3000})
          }else{
              this.updateList(i,wishlist,arr)
              const count=parseInt(this.wishlist_count) - 1
              this.shared.changeWishListCount(count.toString())
              this.toast.successToastr(this.LANG.product_removed_from_wishlist,"",{position:'top-right',toastTimeout:3000})
          }
      },respagesError => {
          const error = this.error.getError(respagesError);
          if(error == "Gateway timeout"){
            return
          }
          this.toast.errorToastr(error,this.LANG.error,{position:'top-right',toastTimeout:3000})
    }))
  }

  updateList(i,wishlist,arr){
      const wish= wishlist == "0" ? '1':'0';
      if(arr == 'special_offer'){
        this.special_offer[i].wishlist=wish
        return
      }
      if(arr == 'random_products'){
        this.random_products[i].wishlist=wish
        return
      }
      if(arr == 'food_salads'){
        this.food_salads[i].wishlist=wish
        return
      }
      if(arr == 'cake'){
        this.cake[i].wishlist=wish
        return
      }
      if(arr == 'cake1'){
        this.cake1[i].wishlist=wish
        return
      }
  }

  addToCart(data,i,arr){
    if(!this.logged_in){
      this.router.navigate(['/login'],{state:{product_id:data.id}})
      return
    }
    if(data.option_flag == "1"){
      const url=this.goToProductDetails(data)
      this.router.navigate([url])
      return
    }
    this.enableLoader(arr,i)
    const data1={
      "product_id":data.id,
      "quantity":"1"
    }
    this.subscriptions.push(this.cart.insertCart(data1).subscribe(result=>{
      if(result.status){
          this.enableLoader(arr,i)
          this.cart_count=result['extra']
          this.shared.changeCount(this.cart_count)
          this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
      }else{
          this.enableLoader(arr,i)
          this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})

      }
  },respagesError => {
      this.enableLoader(arr,i)
      const error = this.error.getError(respagesError);
      if(error == "Gateway timeout"){
        return
      }
      this.toast.errorToastr(error,this.LANG.error,{position:'top-right',toastTimeout:3000})
  }))
  }

  enableLoader(arr,i){
    if(arr == 'special_offer'){
      this.special_offer[i].load=!this.special_offer[i].load
      return
    }
    if(arr == 'random_products'){
      this.random_products[i].load=!this.random_products[i].load
      return
    }
    if(arr == 'food_salads'){
      this.food_salads[i].load=!this.food_salads[i].load
      return
    }
    if(arr == 'cake'){
      this.cake[i].load=!this.cake[i].load
      return
    }
    if(arr == 'cake1'){
      this.cake1[i].load=!this.cake1[i].load
      return
    }
  }

  goToProductLists(data){
    if(data.cake_builder == '1'){
      this.goToCakeBuilder()
      return
    }

    const space=data.url_title.replace(/  /g," ");
    const newTitle=space.replace(/ /g,"-");
      
    this.router.navigate(['/'+newTitle+'/'+btoa(data.id)])
  }

  goToProductDetails(data){
    return '/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)
  }

  encString(data){
    return btoa(data)
  }


  quickView(data){
    this.shared.changeProductDetails(data)
  }

  

  fbShare(data:any){
    const data1:any={
      "title":data.title,
      "description":`${data.category} ${data.sub_category} ${data.title}`,
      "image":data.image,
      "url": window.location.origin+'/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)
    }
    this.seo.setData(data1)
    setTimeout(() => {
      FB.ui({
        method: 'feed',
          name: data.title,
          link: window.location.origin+'/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id),
          picture: data.image,
          description: "Check out awesome flavours of pastries and other sweets.!"
      })
    }, 1000);
    // FB.ui({
    //   method: 'feed',
    //     name: "Saadeddin Pastry shop",
    //     link: window.location.origin,
    //     picture: window.location.origin+"/assets/images/logo.png",
    //     description: "Check out awesome flavours of pastries and other sweets.!"
    // })
  } 

  subscribeNewsLetter(){
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(this.email_id == "" || this.email_id == undefined){
      this.toast.warningToastr(this.LANG.please_enter_email_id,"",{position:'top-right',toastTimeout:30000})
      document.getElementById('news_letter').focus();
      return
    }
    if(!re.test(this.email_id)){
      this.toast.warningToastr(this.LANG.please_enter_valid_email,"",{position:'top-right',toastTimeout:3000})
      document.getElementById('news_letter').focus();
      return
    }
    this.load=true
    const data={
      "email_id":this.email_id
    }

    this.subscriptions.push(this.home.newsLetter(data).subscribe(result=>{
      if(result.status){
        this.load=false
        this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
        this.email_id=""
      }else{
        this.load=false
        this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
      }
    }))

  }

    goToProducts(offer_id){
      if(offer_id){
        this.router.navigate(['/products/'+btoa(offer_id)])
      } 
  }

  bannerClicked(data){
    if(data.type == "1"){
      this.router.navigate(['/'+data.url_category+'/'+btoa(data.id)])
    }else if(data.type == "2"){
      this.router.navigate(['/'+data.url_category+'/'+data.url_sub_category+'/'+ btoa(data.category_id) +'/'+ btoa(data.sub_category_id)])
    }else if(data.type == "3"){
      this.router.navigate(['/products/'+btoa(data.id)])
    }else if(data.type == "4" && data.url){
      if(data.url[0] == "h"){
        // location.href=data.url;
        window.open(data.url,"_blank");
      }else{
        this.router.navigate(['/'+decodeURIComponent(data.url)])
      }
    }
    
  }

  goToCakeBuilder(){
    if(!this.logged_in){
      this.router.navigate(['/login'])
      return
    }
    location.href=this.cake_builder
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe()
    this.subscriptions.map(s => s.unsubscribe());
    document.getElementById('canonical').remove()
    document.getElementById('alternate').remove()
  }

  getSeoDetails(){
    this.subscriptions.push(this.home.getSeoDetals("6").subscribe(result=>{
      if(result){
        this.seo.updateMetaTitle(result['response'])
      }
    }))
  }


  //add dynamic json linked data to the website
  setSchema(){
    this.schema={ 
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Saadeddin Pastry",
    "legalName" : "Saadeddin Pastry",
    "url": window.location.origin,
    "logo": window.location.origin+"/assets/images/logo.svg",
    "foundingDate": "2009",
    "address": {
    "@type": "PostalAddress",
    "streetAddress": "Khalid Bin Waleed Street",
    "addressLocality": "Al Hamra Area P.O. Box 2774",
    "addressRegion": "SA",
    "postalCode": "11461",
    "addressCountry": "SA"
    },
    "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "customer support",
    "telephone": "[+966920017070]",
    "email": "info@saadeddin.com"
    },
  }
    this.load_schema=true
  }


  setCanocialUrl(){
    const link = document.createElement('link');
    link.id = 'canonical';
    link.rel = 'canonical';
    link.href = "https://www.saadeddin.com/"
    const link1 = document.createElement('link');
    link1.id = 'alternate';
    link1.rel = 'alternate';
    link1.media = 'only screen and (max-width: 640px)';
    link1.href = "https://www.saadeddin.com/"
    document.head.prepend(link1);
    document.head.prepend(link);
  }

  openModal(){
    $("#popupModal").modal('show');
  }

  closeModal(){
    localStorage.setItem('myVariable', 'true');
    
    
    // const myVariable = localStorage.getItem('myVariable');
    // this.popupImageDisplay = myVariable
    $("#popupModal").modal('hide');
  }

 


} 