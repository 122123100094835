import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment'
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})

export class errorHandlerService{
	_error = ""
	public LANG:any;
	constructor(private router:Router){
		if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
            this.LANG = environment.arabic_translation;
        } 
        else {
            this.LANG = environment.english_translation;
		}
	}
	getError(error :any){
		var error_code = parseInt(JSON.stringify(error.status),10);
		if(error_code == 400 || error_code == 401 || error_code == 403){
			this._error =  "Forbidden Request";
		}
		else if(error_code == 404)
			this._error = "Not Found";
		else if(error_code == 500)
			this._error =  "Internal Server Error";
		else if(error_code == 503 || error_code == 504) { 
			this._error ="Gateway timeout"
			this.router.navigate(['/'])
		}
		else if(error_code == 0){
			this._error =this.LANG.check_internet_connection;
		}
		else {
			this._error =  this.LANG.something_went_wrong;
		}
		return this._error;
		}
	
}

