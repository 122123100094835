import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class SeoService {
  constructor(private title: Title, private meta: Meta) { }

  updateMetaTitle(data:any) {
    this.title.setTitle(data.page_title);
    this.meta.updateTag({ name: 'title', content: data.meta_title })
    this.meta.updateTag({ name: 'description', content: data.meta_description })
    this.meta.updateTag({ name: 'keywords', content: data.meta_keywords })
    this.addHeader(data)
  }

  addHeader(data:any) {
    this.removeHeader()
    if(data.h2_tag){
      const h2 = document.createElement("H2");
      h2.id = 'h2-title';
      h2.className = 'seo-tags';
      h2.innerHTML = data.h2_tag;
      document.body.prepend(h2);
    }
    if(data.h1_tag){
      const h1 = document.createElement("H1");
      h1.id = 'h1-title';
      h1.className = 'seo-tags';
      h1.innerHTML = data.h1_tag;
      document.body.prepend(h1);
    }
}

removeHeader(){
  const h_tag1=document.getElementById("h1-title");
    if(h_tag1){
        h_tag1.remove()
    }
    const h_tag2=document.getElementById("h2-title");
    if(h_tag2){
        h_tag2.remove()
    }
}


setData(data:any) {
  this.setTitle(data.title,data.description,data.image,data.url);
}

private setTitle(title: string = '',description,image,url) {
  if (title && title.length) {
    this.meta.updateTag({property: 'og:image', content: image});
    this.meta.updateTag({property: 'og:title', content: title});
    this.meta.updateTag({property: 'og:url', content: url});
    this.meta.updateTag({property: 'og:description', content: description});
  } else {
    this.meta.removeTag(`name='twitter:title'`);
    this.meta.removeTag(`name='twitter:image:alt'`); 
    this.meta.removeTag(`property='og:image'`);
    this.meta.removeTag(`property='og:title'`); 
    this.meta.removeTag(`property='og:description'`);
    this.meta.removeTag(`property='og:url'`);
  } 
}

}