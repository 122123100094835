import { Component,OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { sharedService } from '../../SharedResources/Services/shared.service';
import { HomeService } from '../../SharedResources/Services/home.service';
import { SeoService } from '../../SharedResources/Services/seoService';
import { Subscription } from 'rxjs';


 
@Component({
    templateUrl: './about-us.html',
})

export class aboutUsComponent implements OnInit { 
    public LANG:any;
    public unsubscription:Subscription;
    public seoSubscription:Subscription;
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"
    

    constructor(private shared:sharedService,private home:HomeService,private seo:SeoService){
        this.unsubscription=this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("about-us")){
                this.changeLanguage();
            }
          })
          this.changeLanguage();
          window.scroll(0,0)
    }

    ngOnInit(){
    }


    //Change Language from arabic to english and vice-versa
    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
      }


      //sets page title,meta title,meta descriptiuon and meta keywords dynamically
      getSeoDetails(){
        this.seoSubscription=this.home.getSeoDetals("1").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        })
      }

      ngOnDestroy(): void {
        this.unsubscription.unsubscribe()
        this.seoSubscription.unsubscribe()
      }



} 