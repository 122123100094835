import {Injectable} from '@angular/core';
import {apiServiceComponent} from './api.service';


@Injectable({providedIn: 'root'})
export class HomeService{
    private url : string = "";

	constructor(private api : apiServiceComponent){
    }

    homePage(){
        this.url = "home_page";
        let query = "";
        return this.api.get(this.url, query);
    }

    homePage1(){
        this.url = "web_home_page1";
        let query = "";
        return this.api.get(this.url, query);
    }

    homePage2(){
        this.url = "web_home_page2";
        let query = "";
        return this.api.get(this.url, query);
    }

    productListing(data){
        this.url = "product_list";
        return this.api.post(this.url, data); 
    }

    filters(category_id,subcategory_id){
        this.url = "filters";
        let query= "?category_id="+category_id;
        if(subcategory_id){
            query=query+"&sub_category_id="+subcategory_id
        }
        return this.api.get(this.url, query); 
    }

    bestSellProducts(category_id,subcategory_id){
        this.url = "best_sells_product";
        let query=""
        if(category_id && subcategory_id){
            query= "?category_id="+category_id+"&sub_category_id="+subcategory_id;
        }
        return this.api.get(this.url, query); 
    }

    productDetail(product_id){
        this.url = "product_detail";
        let query= "?product_id="+product_id;
        return this.api.get(this.url, query); 
    }

    relatedProducts(product_id){
        this.url = "related_product";
        let query= "?product_id="+product_id;
        return this.api.get(this.url, query); 
    }

    newsLetter(data){
        this.url = "newsLetter";
        return this.api.post(this.url, data); 
    }

    contact(){
        this.url = "contact_us";
        let query = ""
        return this.api.get(this.url, query); 
    }

    sendContactData(data){
        this.url = "contact_request";
        return this.api.post(this.url, data); 
    }

    reviewList(id,index,size){
        this.url = "feedback_list";
        let query = "?id="+id+"&index="+index+"&size="+size
        return this.api.get(this.url, query); 
    }

    applyJob(data){
        this.url = "insert_careers";
        return this.api.post(this.url, data); 
    }

    careers(){
        this.url = "careers";
        let query = ""
        return this.api.get(this.url, query); 
    }

    getSeoDetals(type:any){
        this.url = "seo";
        let query = "?type="+type
        return this.api.get(this.url, query); 
    }

    franchise(){
        this.url = "get_franchise_details";
        let query = ""
        return this.api.get(this.url, query); 
    }

    applyFanchise(data){
        this.url = "apply_franchise";
        return this.api.post(this.url, data); 
    }


}