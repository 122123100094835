import {Injectable,EventEmitter} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})

export class sharedService {

    private productDetalisId!:any;
    private cartCount:string="0";
    private wishlistCount:string="0";
    private token:string="";
    private footer_data:any={};
    private isLoggedIn:boolean=false;
    private blogList:any=[];
    private updateBblogList=new Subject<any>();
    private count = new BehaviorSubject(this.cartCount);
    private login_token = new BehaviorSubject(this.token);
    private wishlist = new BehaviorSubject(this.wishlistCount);
    private footerData = new BehaviorSubject(this.footer_data);
    private loggedIn = new BehaviorSubject(this.isLoggedIn);
    private product_details_id = new BehaviorSubject(this.productDetalisId);
    private language=new Subject();

    currentCount =this.count.asObservable();
    currentToken =this.login_token.asObservable();
    currentWishlistCount =this.wishlist.asObservable();
    currentUser =this.loggedIn.asObservable();
    currentDetails = this.product_details_id.asObservable();
    currentFooterData = this.footerData.asObservable();
    cartChanged = new EventEmitter();
    wishlistChanged = new EventEmitter();
    

    


    changeCount(count:string){
        this.count.next(count);
    }

    getBlogList(){
        return this.updateBblogList.asObservable();
    }

    changeBlogList(data:any){
        this.blogList=data
        this.updateBblogList.next([...this.blogList]);
    }

    changeWishListCount(count:string){
        this.wishlist.next(count);
    }

    changeUser(user:boolean){
        this.loggedIn.next(user);
    }

    changeProductDetails(data:any){
        this.product_details_id.next(data);
    }

    changeToken(token:string){
        this.login_token.next(token);
    }

    setLanguage(res){
        this.language.next(res);
    }
    getLanguage(){
        return this.language.asObservable();
    }

    emitcartChanged(data: string) {
        this.cartChanged.emit(data);
    }

    emitwishlistChanged(data: any) {
        this.wishlistChanged.emit(data);
    }

    changeFooterData(data:any){
        this.footerData.next(data);
    }

 
        

    
}