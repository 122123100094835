import { Component,OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from "../../../../environments/environment";
import { sharedService } from 'src/app/SharedResources/Services/shared.service';
import { BlogService } from 'src/app/SharedResources/Services/blog.service';
import { HomeService } from 'src/app/SharedResources/Services/home.service';
import { SeoService } from 'src/app/SharedResources/Services/seoService';
import { Router } from '@angular/router';



 
@Component({
    templateUrl: './blogList.html', 
})

export class blogComponent implements OnInit { 
    public blogs:any=[];
    public blogsLoaded:boolean=false;
    public LANG:any;
    public subscriptions:Subscription[]=[];
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"
    

    constructor(private shared:sharedService,private blog:BlogService,private home:HomeService,private seo:SeoService,private router:Router){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("blogs")){
                this.changeLanguage();
            }
          }))
          this.subscriptions.push(this.shared.getBlogList().subscribe(data=>{
            this.blogs=data;            
          }))
          this.changeLanguage();
          window.scroll(0,0)
    }

    ngOnInit(){    
      if(this.blogs.length == 0){
        this.getBlogList();
      }else{
        this.blogsLoaded=true;
      }
      this.getSeoDetails();
    }


    getBlogList(){
      this.subscriptions.push(this.blog.blogList().subscribe(result=>{
        if(result){
          this.blogsLoaded=true;
          this.blogs=result.response;
          this.blogs.map(data=>{
            data.date=this.formatDate(data.date);
          })
          this.shared.changeBlogList(this.blogs);
        }else{
          this.blogsLoaded=true;
        }
      }))
    }


    //Change Language from arabic to english and vice-versa
    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
      }



      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }


      formatDate(value:any){
        const date=value.split(" ")[0]
        const day=date.split("-")[2]
        const month=date.split("-")[1]
        const year=date.split("-")[0]
          return `${this.getMonth(month)} ${day},${year}`
          // return `${this.getMonth(month)} ${day},${year} ${time}`
      }
  
      getMonth(index){
        const months = ["January","February","March","April","May",
        "June","July","August", "September","October","November","December"];
        return months[index-1]
      }
  
      getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("19").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

      goToBlogDetails(blog){
        this.router.navigate(['/blog-details/'+btoa(blog.id)])
      }



} 