import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { loaderModule } from './loader.module';
import { contactUsComponent } from '../pages/Contact/contact';
import { aboutUsComponent } from '../pages/About/about-us';
import { missionWhyUsComponent } from '../pages/MissionVission/missionWhyUs';
import { ourBranchesComponent } from '../pages/OurBranches/our-branches';
import { termsAndConditionsComponent } from '../pages/TermsAndConditions/tersmAndConditions';
import {AgmCoreModule} from '@agm/core';
import { filterModule } from './filter.module';
import { careerComponent } from '../pages/Career/CareerList/careers';
import { JobDescriptionComponent } from '../pages/Career/CareerDetails/jobDescription';
import { chairmanComponent } from '../pages/ChairmanMessage/chairmanComponent';
import { qualityPolicyComponent } from '../pages/QualityPolicy/qualityPolicyComponent';
import { partnersComponent } from '../pages/Partners/partners';
import { AngularFireModule } from "@angular/fire";
import {AngularFireStorageModule} from "@angular/fire/storage";
import { environment } from 'src/environments/environment';





const ChildRoutes: Routes = [
    {
        path: 'contact-us',
        component:contactUsComponent,
      },
      {
        path: 'about-us',
        component:aboutUsComponent,
      },
      {
        path: 'mission-vission-why-us',
        component:missionWhyUsComponent,
      },
      {
        path: 'our-branches',
        component:ourBranchesComponent,
      },
      {
        path: 'terms-&-conditions',
        component:termsAndConditionsComponent,
      },
      {
        path:'careers',
        component:careerComponent
      },
      {
        path:'job-description/:id',
        component:JobDescriptionComponent
      },
      {
        path:'chairman-message',
        component:chairmanComponent
      },
      {
        path:'quality-policy',
        component:qualityPolicyComponent
      },
      {
        path:'partners',
        component:partnersComponent
      },
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    loaderModule,
    filterModule,
    AgmCoreModule.forRoot({
      apiKey:"AIzaSyBxu6gRfla-EaaKmiR7afHqnzxjLbEIi40",
      libraries: ['places']
    }),
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, "cloud"), 
  ],
  declarations:[
    contactUsComponent,
    aboutUsComponent,
    missionWhyUsComponent,
    ourBranchesComponent,
    termsAndConditionsComponent,
    JobDescriptionComponent,
    careerComponent,
    chairmanComponent,
    qualityPolicyComponent,
    partnersComponent
  ]
})
export class othersModule { }
