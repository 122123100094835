import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { loaderModule } from './loader.module';
import { loginComponent } from '../pages/Login/login';
import { registrationComponent } from '../pages/Registration/registration';




const ChildRoutes: Routes = [
    {
        path: 'login',
        component:loginComponent
      },
      {
        path: 'registration',
        component:registrationComponent
      },
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    loaderModule,
  ],
  declarations:[
    loginComponent,
    registrationComponent
  ]
})
export class loginModule { }
