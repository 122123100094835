import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { faqComponent } from '../pages/FAQ/faq';





const ChildRoutes: Routes = [
      {
        path:'faq',
        component:faqComponent
      },
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    CommonModule,
  ],
  declarations:[
    faqComponent
  ]
})
export class faqModule { }
