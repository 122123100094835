import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { blogComponent } from '../pages/Blog/BlogList/blogList';
import { blogDetailsComponent } from '../pages/Blog/BlogDetails/blogDetails';





const ChildRoutes: Routes = [
    
      {
        path:'blogs',
        component:blogComponent
      },
      {
        path:'blog-details/:id',
        component:blogDetailsComponent
      },
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
  ],
  declarations:[
    blogComponent,
    blogDetailsComponent
  ]
})
export class blogModule { }
