import { Component,OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { sharedService } from '../../SharedResources/Services/shared.service';
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './faq.html', 
})

export class faqComponent implements OnInit { 
    public LANG:any;
    public subscriptions:Subscription[]=[]
    

    constructor(private shared:sharedService,private seo:SeoService,private home:HomeService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("faq")){
                this.changeLanguage();
            }
          }))
          this.changeLanguage();
          window.scroll(0,0)
    }

    ngOnInit(){
	}
	
	changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
      }

      

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }



} 