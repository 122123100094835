import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';

import { loaderModule } from './loader.module';
import { productListingComponent } from '../pages/Products/ProductList/productListing';
import { productDetailsComponent } from '../pages/Products/ProductDetails/productDetails';
import { jsonLDModule } from './jsonLD.module';




const ChildRoutes: Routes = [
  {
    path: ':category/:subcategory/:category_id/:subcategory_id',
    component:productListingComponent
  },
  {
    path: 'products/:offer_id',
    component:productListingComponent
  },
  {
    path: ':category/:category_id',
    component:productListingComponent
  },
  {
    path: 'product/:category/:subcategory/:product_name/:id',
    component:productDetailsComponent
  },
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    loaderModule,
    jsonLDModule
  ],
  declarations:[
    productListingComponent,
    productDetailsComponent,
  ]
})
export class productsModule { }
