import { Component,OnInit, Input } from '@angular/core';
import { OrderService } from '../../../../SharedResources/Services/order.service';
import { environment } from "../../../../../environments/environment";
import { sharedService } from '../../../../SharedResources/Services/shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SeoService } from '../../../../SharedResources/Services/seoService';
import { HomeService } from '../../../../SharedResources/Services/home.service';
declare const $:any;


@Component({
    selector:'profile-order-details',
    templateUrl: './profile-orderDetails.html',
})

export class profileOrderDetailsComponent implements OnInit {
    public order_details:any={}
    public currency:string="SAR"
    public width:any="25"
    public LANG:any;
    public ratings:number=5;
    public comment:string;
    public detail_id:string;
    public product_id:string;
    public load:boolean=false;
    public cash_on_text:boolean=false;
    public subscriptions:Subscription[]=[]

    @Input() order_id: boolean;
    

    constructor(private order:OrderService,private shared:sharedService,private router:Router,private toast:ToastrManager,private seo:SeoService,private home:HomeService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            this.width="25"
            this.changeLanguage();
            this.getOrderDetails()
        }))
        this.changeLanguage();
        window.scroll(0,0)
    }

    ngOnInit(){
        this.getOrderDetails()
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
          this.currency="ريال"
        }
        else {
          this.LANG = environment.english_translation;
          this.currency="SAR"
        }
        this.getSeoDetails()
      }

    getOrderDetails(){
      this.subscriptions.push(this.order.orderDetails(this.order_id).subscribe(result=>{
            if(result){ 
                this.order_details=result
                this.order_details.expected_delivery_date=this.formatDate(this.order_details.expected_delivery_date,2)
                const status=this.order_details.status_list.filter(data=>{
                    return data.created_on
                })
                this.order_details.status_list.map(data=>{
                  if(data.created_on)
                  data.created_on=this.formatDate(data.created_on,1)
                })
                this.width=parseInt(this.width) * status.length
                if(this.order_details.pickup_store){
                  this.cash_on_text=this.LANG.cash_on_pickup
                }else{
                  this.cash_on_text=this.LANG.cash_on_delivery
                }
            }
        }))
    }

    ngOnDestroy(): void {
      this.subscriptions.map(s => s.unsubscribe());
      }

      goToProductDetails(data){
        if(data.product_id == "100001" || data.product_id == "100000" || data.product_id == "100002"){ 
            return
          }
        this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.product_id)])
      }

      rate(value:number){
        this.ratings=value
      }

      openModal(data){
        this.clearRatings()
        setTimeout(() => {
          this.detail_id=data.order_detail_id
          this.product_id=data.product_id  
        }, 100);
      }

      clearRatings(){
        this.load=false
        this.ratings=5;
        this.comment=""
        this.product_id=""
        this.detail_id=""
      }

      insertFeedBack(){
        if(this.comment == "" || this.comment == undefined){
          this.toast.warningToastr(this.LANG.Please_add_your_Comment,"",{ position: "top-right", toastTimeout: 3000 });
          document.getElementById('comment').focus();
          return
        }
        this.load=true
          const data={
            "order_detail_id": this.detail_id,
            "product_id": this.product_id,
            "rating": this.ratings,
            "comment": this.comment 
          }
          this.subscriptions.push(this.order.feedback(data).subscribe(result=>{
              if(result.status){
                const index = this.order_details.order_detail.findIndex( data => data.product_id == this.product_id)
                this.order_details.order_detail[index].feedback=this.ratings
                this.clearRatings()
                this.closeModal()
                this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
                this.load=false
              }else{
                this.load=false
                this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
              }
          }))
      }

      closeModal(){
        this.clearRatings()
        $("#ReviewModal").modal("hide")
      }
    

      formatDate(value:any,type:number){
        const time=`${value.split(" ")[1]} ${value.split(" ")[2]}`
        const date=value.split(" ")[0]
        const day=date.split("-")[2]
        const month=date.split("-")[1]
        const year=date.split("-")[0]
        if(type ==1){
          return `${this.getMonth(month)} ${day},${year}`
        }
          return `${this.getMonth(month)} ${day},${year} ${time}`
      }

      getMonth(index){
        const months = ["January","February","March","April","May",
        "June","July","August", "September","October","November","December"];
        return months[index-1]
      }

      getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("13").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

    

} 