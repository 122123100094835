import {Injectable} from '@angular/core';
import {apiServiceComponent} from './api.service';


@Injectable({providedIn: 'root'})
export class BlogService{
    private url : string = "";

	constructor(private api : apiServiceComponent){
    }


    

    blogList(){
        this.url = "blog";
        let query = "";
        return this.api.get(this.url, query);
    }

    blogDetails(blog_id){
        this.url = "blog";
        let query = "?id="+blog_id;
        return this.api.get(this.url, query);
    }

    



}