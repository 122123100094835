import { NgModule } from '@angular/core';
import { JsonLdComponent } from '../SharedResources/Components/JSON-LD/json-ldComponent';







@NgModule({
  imports: [
  ],
  declarations:[
      JsonLdComponent
  ],
  exports:[
    JsonLdComponent
  ]
})
export class jsonLDModule { }
