import { NgModule } from '@angular/core';
import { SearchPipe } from '../SharedResources/pipes/search.pipe';







@NgModule({
  imports: [
  ],
  declarations:[
      SearchPipe
  ],
  exports:[
    SearchPipe
  ]
})
export class filterModule { }
